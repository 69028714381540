<template>
    <div id="active" class="tab-pane active tab-box">
        <div class="orders-lst skelton" v-if="is_skel_loader && ongoingsubscriptions.length == 0">
            <a href="javascript:void(0);">
                <div class="order-item d-flex align-items-start mb-2 pb-2">
                    <div class="img-cls resimg-squre | d-flex align-items-center justify-content-center">
                        <skeleton-loader-vue width="85px" height="85px" style="border-radius: 5px;" />
                    </div>
                    <div class="order-data ms-3 d-flex align-items-start justify-content-between w-100">
                        <div class="orders-status-main delivery-link">
                            <h6 class="mb-1"><skeleton-loader-vue type="string" width="130px" height="20px" /></h6>
                            <skeleton-loader-vue type="string" width="50px" height="20px" />
                            <p class="mt-2"><skeleton-loader-vue type="string" width="100px" height="20px" /></p>
                        </div>
                        <div class="order-status">
                            <p class="order-status-btn"><skeleton-loader-vue type="circle" width="100px" height="20px"
                                    style="border-radius: 20px !important;" /></p>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="orders-lst">
            <router-link v-if="ongoingsubscriptions.length > 0 && !is_skel_loader"
                v-for="(order, index) in ongoingsubscriptions" :key="index"
                :to="{ name: 'subscription-status', params: { subscription_id: base64_encode(order.subscription_id) } }">

                <div class="order-item d-flex align-items-start mb-2 pb-2">
                    <div class="img-cls resimg-squre">
                        <img :src="order.item.item_image" />
                        <div class="sub-cancle">
                            <p>{{ $t('cancel') }}</p>
                        </div>
                    </div>
                    <div class="order-data ms-3 d-flex align-items-start justify-content-between w-100  ms-3">
                        <div class="orders-status-main delivery-link on-going-order">
                            <h6 class="mb-1">{{ order.item.item_name }}</h6>
                            <span class="mx-1">{{ $t('Subscription cancel at') }}</span>
                            {{ moment(order.subscription_cancel_date).format('D MMM YYYY') }}
                        </div>
                        <!-- <div class="order-status" v-if="order.subscription_type == 1">
                <p class="readytoserve order-status-btn">Daily</p>
              </div>
              <div class="order-status" v-else-if="order.subscription_type == 2">
                <p class="readytoserve order-status-btn">Interval</p>
              </div>
              <div class="order-status" v-else-if="order.subscription_type == 3">
                <p class="readytoserve order-status-btn">Custom</p>
              </div> -->
                    </div>
                </div>
            </router-link>
            <div v-if="ongoingsubscriptions.length == 0 && !is_skel_loader"
                class="order-offers | position-absolute top-50 start-50 translate-middle">
                <p class="text-center">{{ $t('No subscription found') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
    props: ["selectTab"],
    components: { 'skeleton-loader-vue': VueSkeletonLoader },
    computed: {
        ...mapGetters("order", ["onGoingSubscripitons"]),
    },
    data() {
        return {
            ongoingsubscriptions: [],
            moment: moment,
            is_skel_loader: true,
        };
    },
    mounted() {
        if (this.selectTab == 2) {
            this.getOnGoingSubscriptionData();
        }
    },
    methods: {
        ...mapActions("order", ["getOnGoingSubscription"]),
        getOnGoingSubscriptionData() {

            this.getOnGoingSubscription({
                vendor_id: this.$auth.getVendorId(),
                is_langauge: this.$store.state.lang.locale,
                search: 3,
                // user_id: "",
                // totalrecord: 100,
                // skip: 0,
            }).then((data) => {
                if (data.code == 200) {
                    this.is_skel_loader = false;
                    this.ongoingsubscriptions = data.subscription.reverse();
                } else {
                    this.is_skel_loader = false;
                }
            });
        }
    }
};
</script>

<style scoped>
.modal-content {
    height: 30vh;
}

.sub-cancle p {
    margin-bottom: -20px;
    background-color: #e71515;
    font-size: small;
    color: white;
}
</style>