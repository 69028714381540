<template>
  <div class="login-main">
    <nav-bar v-on:sendDefaultAddress="getAddress" :checkpage="page"></nav-bar>
    <section class="review-order-start box-flex">
      <div class="container review-main text-capitalize position-relative review-container-nw box-flex__wrapper">
        <div class="row">
          <div class="col-md-12 p-0">
            <div v-if="cartItemCount > 0">
              <div class="center-card" v-if="isTemplateShow">
                <div class="center-card-box | p-3 top-sticky border-bottom">
                  <div class="resturant-details | d-flex align-items-center">
                    <div class="rst-nme-add">
                      <h4 class="mt-0">
                        {{ $t("Your Cart")
                        }}<span class="total-items">({{
                          getSubscriptionItem.reduce(
                            (a, c) => a + c.quantity,
                            0
                          )
                        }}
                          {{
                            getSubscriptionItem.reduce(
                              (a, c) => a + c.quantity,
                              0
                            ) > 1
                              ? $t("Items")
                              : $t("Item")
                          }})</span>
                      </h4>
                      <p class="frm-txt">
                        {{ $t("From") }} :
                        <a href="javascript:void(0)" v-if="!restaurantData.slug"><skeleton-loader-vue type="string"
                            width="50%" height="20px" /></a>
                        <router-link v-else :to="{
                          name: 'vendor',
                          params: { restaurant_name: restaurantData.slug },
                        }" class="resst-lnk">{{ this.restaurantData.name }}</router-link>
                      </p>
                    </div>
                    <!-- <div class="clen-crt-btn ms-auto">
                      <p class="crt-clnt-txt" @click="cleanCart()">
                        {{ $t("Clear Cart") }}
                      </p>
                    </div> -->
                  </div>
                </div>

                <div class="order-items-main plr15 review-cart-item">

                  <div class="order-item d-flex align-items-center justify-content-between mb-0"
                    v-for="(item, index) in this.getSubscriptionItem" :key="index">
                    <div class="item-heading-main">
                      <!-- <div class="crt-img crt-img-nw" v-if="item.image && item.item_type != '2'" -->

                      <div class="veg nveg mb-4" v-if="
                        item.item_type == '0' &&
                        $auth.setting.is_cart_image == '0'
                      ">
                        <span></span>
                      </div>
                      <div class="non-veg nveg mb-4" v-if="
                        item.item_type == '1' &&
                        $auth.setting.is_cart_image == '0'
                      ">
                        <span></span>
                      </div>

                      <div class="crt-img crt-img-nw" data-bs-toggle="modal" data-bs-target="#imageModal" v-if="
                        item.image ||
                        item.item_type == '0' ||
                        item.item_type == '1'
                      " :class="item.image == '' || item.image == null ? 'noimg' : ''
                        ">
                        <!-- <img v-if="item.image" :src="item.image" @error="$event.target.src = noImge" /> -->
                        <!-- <div class="free-offer" v-if="freePromocode.is_free_order_item == 1">
                          <p>{{ freePromocode.name_for_promocode + ' ' + $t('for Subscription Only') }}</p>
                        </div> -->
                        <img v-if="
                          item.image && $auth.setting.is_cart_image == '1'
                        " :src="item.image" @error="$event.target.src = noImge"
                          @click="openImagePreview(item.image, item.name)" />

                        <div class="veg veg-non-nw" v-if="
                          item.item_type == '0' &&
                          $auth.setting.is_cart_image == '1'
                        ">
                          <span></span>
                        </div>
                        <div class="non-veg veg-non-nw" v-if="
                          item.item_type == '1' &&
                          $auth.setting.is_cart_image == '1'
                        ">
                          <span></span>
                        </div>
                        <!-- <img src="@/assets/images/non_veg.png" /> -->
                      </div>
                      <div class="item-heading item-heading-nw">
                        <h6 class="item-c-heading">{{ item.name }} :</h6>
                        <p class="item-c-price m-0">
                          <span class="item-price" v-if="item.price != '0'">
                            <span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>
                            {{ item.price.toFixed(2) }}</span>
                        </p>
                        <div class="customized customized-primary d-inline-block" v-if="item.selected_type.length > 0">
                          <p class="customized-lable text-start">customized</p>
                          <div class="selected_type flex-column">
                            <div class="selected-main">
                              <p v-for="(subitem, i) in item.selected_type" :key="i">
                                {{ subitem }}
                                <span v-if="
                                  i !=
                                  Object.keys(item.selected_type).length - 1
                                ">,</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="d-flex flex-column align-items-center text-end justify-content-center mobiitem-nw">
                      <div class="number d-flex align-items-start counter-nw">
                        <span class="minus d-flex justify-content-center align-items-center"
                          @click="minusItem(item, index)">-</span>

                        <input type="text" :value="item.quantity" readonly />
                        <span class="plus d-flex justify-content-center align-items-center"
                          @click="plusItem(item, index)">+</span>
                      </div>
                      <div class="final-item-price final-item-price-nw">
                        <p class="mb-0">
                          <span id="currency" v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{
                            parseFloat(getTotalItemPrice(item)).toFixed(2) }}
                        </p>
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="plr15 review-section">
                  <div class="review-boxs">
                    <!-- <h6 class="mb-2">{{ $t("special instructions") }}</h6> -->
                    <input class="form-control mb-0 h50" type="search" :placeholder="instructionPlaceholder"
                      aria-label="instructions" v-model="instructions" />
                  </div>
                  <div class="review-boxs mt-4 promocode-sec border-bottom">
                    <h6 class="mb-2">{{ $t("Subscriptions Type") }}</h6>

                    <div v-if="subscription_type == 1">

                      <p class="mb-2"> {{ $t("Everyday") }}</p>
                      <p class="mx-1 mb-2">{{ subscriptionData.quantity }} {{ $t("Quantity Deliver on Everyday") }}</p>
                    </div>
                    <div v-if="subscription_type == 2">
                      <p class="mb-2">{{ $t("On Interval") }}</p>
                      <!-- {{ intervalDays.isModal ? intervalDays.value : intervalDays.label }} -->


                      <p class="mb-2">{{ intervalDays.quantity }} {{ intervalDays.isModal ? `Quantity Deliver Every
                        ${intervalDays.value}th day` : `Quantity Deliver Every
                        ${intervalDays.value}th day`
                        }}</p>
                      <!-- <p class="mb-2">{{ intervalDays.quantity }} Quantity Deliver {{ intervalDays.label }}</p> -->
                    </div>
                    <div v-if="subscription_type == 3">
                      <p class="mb-2">{{ $t("Custom") }}</p>
                      <div class="card-body">
                        <div class="text-start">
                          <ul class="date-list">
                            <!-- {{ filterDayValue }} -->
                            <li class="date-item" v-if="filterDayValue && filterDayValue.length > 0"
                              v-for="(day, index) in filterDayValue" :key="index">
                              <div class="date-day">{{ day.day.slice(0, 3) }}</div>
                              <div class="date-num">
                                {{ day.quantity }}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="">
                                <ul class="date-list">
                                  <li
                                    class="date-item"
                                    v-for="(day, index) in day_date_value"
                                    :key="index"
                                    @click="showCustomModal"
                                  >
                                    <div class="date-day">{{ day.key }}</div>
                                    <div class="date-num">
                                      {{ day.quantity }}
                                    </div>
                                  </li>
                                </ul>
                              </div>
                    </div> -->


                    <a href="javascript:void(0)" @click="SubModals()" class="lnk offer-btn">{{ $t("Edit Quantity")
                      }}</a>
                  </div>
                  <div
                    v-if="this.freePromocode.is_free_order_item == 1 && this.subscriptionData && (this.subscriptionData.quantity > 1 || this.getQuantity > 1 || this.customQuantity > 0)">
                    <p class="text-danger">{{ $t('Note : The Free Order Coupon is valid for a single item only and
                      cannot be applied to multiple quantities. ') }}</p>
                  </div>
                  <div class="border-bottom"
                    v-if="this.freePromocode.is_free_order_item == 1 &&
                      this.subscriptionData && (this.subscriptionData.quantity > 1 || this.getQuantity > 1 || this.customQuantity > 0)">
                    <div class="mt-3">
                      <div class="d-flex justify-content-between">
                        <h6>{{ $t('Use Wallet') }}</h6>
                        <router-link v-if="this.userWallet < this.getTotalAmount()" :to="{
                          name: 'wallet-payment',
                        }">{{ $t('Recharge Wallet') }}</router-link>
                      </div>

                      <span class="wbalance"><span v-html="$auth.setting.currency" class="currency"></span>{{
                        $auth.user.wallet_amount.toFixed(2) }}</span>
                      <div class="" v-if="this.userWallet < this.getTotalAmount()">
                        <p class="text-danger">
                          {{ $t('Insufficient wallet amount please recharge your wallet') }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="border-bottom" v-else-if="this.freePromocode.is_free_order_item == 0">
                    <div class="mt-3">
                      <div class="d-flex justify-content-between">
                        <h6>{{ $t('Use Wallet') }}</h6>
                        <router-link v-if="this.userWallet < this.getTotalAmount()" :to="{
                          name: 'wallet-payment',
                        }">{{ $t('Recharge Wallet') }}</router-link>
                      </div>

                      <span class="wbalance"><span v-html="$auth.setting.currency" class="currency"></span>{{
                        $auth.user.wallet_amount.toFixed(2) }}</span>
                      <div class="" v-if="this.userWallet < this.getTotalAmount()">
                        <p class="text-danger">
                          {{ $t('Insufficient wallet amount please recharge your wallet') }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="review-boxs mt-4" v-if="deliveryInstruction != ''">
                    <h6 class="mb-2">
                      {{
                        $t("Delivery Instruction ", { msg: $auth.setting.driver_name })
                      }}
                    </h6>
                    <div class="tip-drivers">
                      <div v-for="instruction in deliveryInstruction" :key="instruction.id">
                        <input type="checkbox" name="delivery_instruction" class="visually-hidden"
                          :id="'delivery_instruction' + instruction.id" :value="instruction.value"
                          v-model="isInstruction[instruction.id]" @click="
                            getDeliveryInstruction(
                              instruction.value,
                              'delivery_instruction',
                              instruction.id
                            )
                            " />
                        <label :for="'delivery_instruction' + instruction.id" class="cust-radio-btn">
                          {{ instruction.value }}
                        </label>
                      </div>
                    </div>
                  </div>


                  <div class="billing-data custom-checkbox" v-if="
                    $auth.setting &&
                    $auth.setting.contact_less_delivery &&
                    (isPickupDelivery == 1 || isPickupDelivery == -1) &&
                    $auth.setting.is_pickup_enable == 'true' &&
                    $auth.setting.contact_less_delivery == 1
                  ">
                    <div class="d-flex justify-content-between align-items-center form-group">
                      <h6>{{ $t("Opt in for No-contact Delivery") }}</h6>
                      <input class="form-group" id="cntctdelivery" type="checkbox" true-value="1" false-value="0"
                        v-model="contactDelivery" />
                      <label class="form-check-label" for="cntctdelivery"></label>
                    </div>
                    <p class="pcolor">
                      {{
                        this.$auth.setting.terminology &&
                        $auth.setting.terminology[0].contact_less_delivery_text
                      }}
                    </p>
                  </div>

                  <div id="billing-data" class="billing-data">
                    <h6 class="mb-3">{{ $t("Billing Details") }}</h6>
                    <ul>
                      <li class="d-flex justify-content-between">
                        <span v-if="itemTexDisplay() > '0.00'">
                          {{ $t("Item Total") }}
                          <i v-b-tooltip.hover.right.nofade.html.v-secondary="'Included item ' +
                            ($auth.setting.terminology &&
                              $auth.setting.terminology[0].tax_name !== ''
                              ? $auth.setting.terminology[0].tax_name
                              : '') +
                            ' ' +
                            $auth.setting.currency +
                            ' ' +
                            itemTexDisplay()
                            " class="fa fa-info-circle" aria-hidden="true"></i></span>
                        <span v-else>{{ $t("Item Total") }}</span>
                        <span><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{
                          getTotalItemAmount() }}</span>
                      </li>

                      <!-- <li class="d-flex justify-content-between" v-if="packagingCharge > 0">
                      <span>{{ $t("Packaging Charge") }}</span>
                      <span><span v-html="$auth.setting.currency" class="currency"></span>{{
                        parseFloat(packagingCharge).toFixed(2) }}</span>
                    </li>

                    <li class="d-flex justify-content-between" v-if="this.$auth.setting &&
                      (this.$auth.setting.included_tax == 0 || this.$auth.setting.included_tax == 'Yes')">
                      {{ this.$auth.setting && this.$auth.setting.terminology &&
                        this.$auth.setting.terminology[0].tax_name }}({{ this.gstMaxtax.toFixed(2) }}%)
                      <span><span v-html="$auth.setting.currency" class="currency"></span>{{ gstTotalAmount() }}</span>
                    </li> -->

                      <li class="d-flex justify-content-between" v-if="deliveryCharge == 0.0 && isPickupDelivery == 1">
                        {{ $t("Delivery Charges") }}<span>{{ $t("Free") }}</span>
                      </li>

                      <!-- <div v-if="promoCodeType == 5 && deliveryCharge > 0 && isPickupDelivery == 1"> -->
                      <li v-if="
                        promoCodeType == 5 &&
                        promocode &&
                        deliveryCharge > 0 &&
                        isPickupDelivery == 1
                      " class="d-flex justify-content-between">
                        {{ $t("Delivery Charges") }}
                        <div>
                          <span class="text-decoration-line-through">
                            <span v-html="$auth.setting.currency" class="currency"></span>{{ deliveryCharge }}</span>
                          <span class="text-success mx-1">{{ $t("Free") }}</span>
                        </div>
                      </li>

                      <li class="d-flex justify-content-between"
                        v-else-if="deliveryCharge > 0 && isPickupDelivery == 1">
                        {{ $t("Delivery Charges") }}
                        <span>
                          <span v-html="$auth.setting.currency" class="currency"></span>{{ deliveryCharge }}</span>
                      </li>

                      <li class="d-flex justify-content-between" v-if="others_fees > 0">
                        <span>
                          {{ $t("Taxes & Other Fees") }}
                          <i class="fa fa-info-circle" aria-hidden="true" @click="otherFeesModal()"></i></span>
                        <span><span v-html="$auth.setting.currency" class="currency"></span>
                          {{ others_fees.toFixed(2) }}</span>
                      </li>

                      <li class="d-flex justify-content-between" v-if="driver_tip > 0 && isPickupDelivery == 1">
                        {{ $t("Driver Tip")
                        }}<span><span v-html="$auth.setting.currency" class="currency"></span>{{ driver_tip }}</span>
                      </li>

                      <li class="d-flex justify-content-between" v-if="new_driver_tip > 0 && isPickupDelivery == 1">
                        {{ $t("Driver Tip")
                        }}<span><span v-html="$auth.setting.currency" class="currency"></span>{{ other_driver_tip
                          }}</span>
                      </li>

                      <!-- <li class="d-flex justify-content-between" v-if="restaurantData.restaurant_convince_charge > 0">
                      {{ $t("Convenience Charges") }}<span><span v-html="$auth.setting.currency"
                          class="currency"></span>{{ getConvenienceCharge() }}</span>
                    </li> -->
                      <li class="d-flex justify-content-between discount" v-if="promoDiscount">
                        {{ $t("Discount") }}
                        <span>-
                          <span v-html="$auth.setting.currency" class="currency"></span>{{
                            parseFloat(promoDiscount).toFixed(2) }}</span>
                      </li>
                      <li class="d-flex justify-content-between promo-discount" v-if="promoCashback">
                        {{ $t("Cashback")
                        }}<span><span v-html="$auth.setting.currency" class="currency"></span>{{
                          promoCashback.toFixed(2) }}</span>
                      </li>

                      <!-- <li class="d-flex justify-content-between bold" isPickupDelivery == 1>
                      {{ $t("To Pay") }}<span><span v-html="$auth.setting && $auth.setting.currency"
                          class="currency"></span>{{ getTotalAmount() }}</span>
                    </li>  -->
                      <li class="d-flex justify-content-between bold">
                        {{ $t("To Pay")
                        }}<span><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{
                          getTotalAmount() }}</span>
                      </li>
                    </ul>
                    <div class="svprc">
                      <p class="d-flex justify-content-between" v-if="
                        savedPrice() > 0 ||
                        promoDiscount > 0 ||
                        promoCashback > 0
                      ">
                        {{ $t("You Saved") }}
                        <span><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{
                          (
                            savedPrice() +
                            Number(promoDiscount) +
                            promoCashback
                          ).toFixed(2)
                        }}</span>
                      </p>
                    </div>
                    <li v-if="isPickupDelivery == 1 || isPickupDelivery == -1">
                      <p class="pcolor">
                        {{
                          $auth.setting &&
                          $auth.setting.terminology &&
                          $auth.setting.terminology[0].cart_info_text
                        }}
                      </p>
                    </li>
                  </div>
                  <div class="delivery-address plr15">
                    <!-----------Radio Delivery and pickup --------------->
                    <div class="d-flex align-items-center justify-content-between mb-3">
                      <!-- <div class="delivery-pickup-main d-flex align-items-center mb-1" v-if="
                        (deliveryTypeSlots == '2' || deliveryTypeSlots == '') &&
                        $auth.setting &&
                        $auth.setting.is_pickup_enable == 'true' &&
                        checkPickupStatus == 'true'
                      ">
                        <div class="delivery-pickup-lnk me-2">
                          <div class="switch" id="switch">
                            <input name="radio" @click="getDelivery('1')" type="radio" value="1" id="delivery"
                              :checked="isPickupDelivery == 1" />
                            <label for="delivery">{{ $t("Delivery") }}</label>
                            <input name="radio" @click="getDelivery('0')" type="radio" value="0" id="pickup"
                              :checked="isPickupDelivery == 0" />
                            <label for="pickup" class="right">{{
                              $auth.setting.terminology[0].takeaway_text
                                ? $auth.setting.terminology[0].takeaway_text
                                : "Takeaway"
                            }}</label>
                            <span class="swtact" :class="this.rightClass || {
                              right: ['0'].includes(isPickupDelivery),
                            }
                              " aria-hidden="true"></span>
                          </div>
                        </div>
                      </div> -->
                      <!-----------Radio Delivery and pickup --------------->
                      <!-- <h6 class="mb-0">-->


                      <div class="delivery-nw-section"
                        v-if="isPickupDelivery == 1 || isPickupDelivery == -1 || $auth.setting.is_pickup_enable == 'false'">
                        <span v-if="primaryAddress && primaryAddress.adress_clarification
                        ">{{ $t('Delivery To ' + primaryAddress.adress_clarification) }}</span>
                        <div v-if="Object.keys(primaryAddress).length == 0 ||
                          primaryAddress.deliveryAddress == ''
                        " class="addaddrs">
                          <router-link :to="{ name: 'add-address' }">{{
                            $t("Add New Address")
                          }}</router-link>
                        </div>
                        <div v-if="addressList.length > 0" class="chng-addrs">
                          <a href="javascript:void(0)" @click="deliveryModalOpen" class="lnk">{{ $t("Change Address")
                            }}</a>
                        </div>
                        <!-- </h6> -->
                        <p>{{ primaryAddress.deliveryAddress }}</p>
                      </div>
                      <!-- v-if="$auth.setting.is_pickup_enable == 'true' || isPickupDelivery == 0 || isPickupDelivery == -1" -->
                      <div v-else class="pckup-addr-cls">
                        <span class="pickup-txt">{{ $t("Pickup from") }}</span>
                        <div class="map-add-cls">
                          <a :href="'https://www.google.com/maps?saddr=' +
                            latitude +
                            ',' +
                            longitude +
                            '&daddr=' +
                            restaurantLat +
                            ',' +
                            restaurantLong
                            " target="_blank">></a>
                        </div>
                        <div class="pic-cls-con">
                          <p v-bind:title="restaurantData.location_address">
                            {{ this.restaurantData.location_address }}
                          </p>
                        </div>
                      </div>

                      <!-- <div v-if="isPickupDelivery == 0" class="pckup-addr-cls">
                      <span class="pickup-txt">{{ $t("Pickup from") }}</span>
                      <div class="map-add-cls">
                        <a :href="'https://www.google.com/maps?saddr=' +
                          latitude +
                          ',' +
                          longitude +
                          '&daddr=' +
                          restaurantLat +
                          ',' +
                          restaurantLong
                          " target="_blank">></a>
                      </div>
                      <div class="pic-cls-con">
                        <p v-bind:title="restaurantData.location_address">
                          {{ this.restaurantData.location_address }}
                        </p>
                      </div>
                    </div> -->
                    </div>

                    <!-- <div class="full-btn">
                    <a @click="redirectBillingData()" class="btn billing-btn view-dtail-cls">
                      <span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{ getTotalAmount()
                      }}<span class="txtline">{{ $t("View Bill Details") }}</span>
                    </a>
                    <a v-if="parseInt(getTotalItemAmount()) < parseInt(miniumOrderValue)" href="javascript:void(0);"
                      class="prcc-cls">{{ $t("Minimum Order Value") }}
                      <span v-html="$auth.setting.currency" class="currency"></span>{{ miniumOrderValue }}
                    </a>
                    <b-button variant="primary" class="review-cart-btn" disabled v-else-if="is_btn_spinner"><b-spinner
                        small type="grow"></b-spinner>{{ $t("Loading") }}...</b-button>
                    <a v-else href="javascript:void(0);" class="prcc-cls-blr" @click="processToPay()">{{ $t("Proceed To
                                          Pay") }}</a>
                    <router-link ref="
                    " style="display: none !important" :to="{ name: 'payment' }">{{
                      $t("Proceed To Pay") }}</router-link>
                  </div> -->

                    <div class="full-btn">
                      <a href="#billing-data" class="btn billing-btn view-dtail-cls">
                        <span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{
                          getTotalAmount()
                        }}<span class="txtline">{{
                          $t("View Bill Details")
                          }}</span>
                        <!-- <span class="wbalance"><span v-html="$auth.setting.currency" class="currency"></span>{{$auth.user.wallet_amount}} {{$t('Wallet balance')}}</span> -->
                      </a>
                      <!-- <a v-if="
                        getTotalItemAmount() <
                        parseInt(this.restaurantData.minimum_order_value)
                      " href="javascript:void(0);" class="prcc-cls">{{ $t("Minimum Item Total")
                        }}<span v-html="$auth.setting.currency" class="currency"></span>{{
                          this.restaurantData.minimum_order_value }}</a> -->
                      <!-- <b-button variant="primary" class="review-cart-btn"><b-spinner small type="grow"></b-spinner>{{
                        $t("Loading") }}...</b-button> -->
                      <!-- <a v-if="this.userWallet < this.getTotalAmount()" href="javascript:void(0);" class="prcc-cls-blr"
                        disabled @click="processToPay()">{{ $t("Proceed
                        To
                        Pay") }}</a> -->

                      <!-- <a :href="canSubscribe ? 'javascript:void(0);' : null" class="prcc-cls-blr"
                        :class="{ 'disabled-link': !canSubscribe }" @click="canSubscribe ? processToPay() : null">
                        {{ $t("Subscribe") }}
                      </a> -->

                      <a v-if="freePromocode.is_free_order_item == 1"
                        :href="canSubscribe ? 'javascript:void(0);' : null" class="prcc-cls-blr"
                        :class="{ 'disabled-link': !canSubscribe }" @click="canSubscribe ? processToPay() : null">
                        {{ $t("Subscribe") }}
                      </a>

                      <a v-else-if="this.freePromocode.is_free_order_item == 0"
                        :href="userWallet >= getTotalAmount() && 'javascript:void(0);'" class="prcc-cls-blr"
                        :class="{ 'disabled-link': userWallet < getTotalAmount() }"
                        @click="userWallet >= getTotalAmount() ? processToPay() : null">
                        {{ $t("Subscribe") }}
                      </a>



                      <!-- <a v-if="this.freePromocode.is_free_order_item == 1 && (this.intervalDays.quantity > 1) ||
                        (this.subscriptionData && this.subscriptionData.quantity > 1)"
                        :href="userWallet >= getTotalAmount() && 'javascript:void(0);'" class="prcc-cls-blr"
                        :class="{ 'disabled-link': userWallet < getTotalAmount() }"
                        @click="userWallet >= getTotalAmount() ? processToPay() : null">
                        {{ $t("Subscribe") }}
                      </a>

                      <a v-else-if="this.freePromocode.is_free_order_item == 0"
                        :href="userWallet >= getTotalAmount() && 'javascript:void(0);'" class="prcc-cls-blr"
                        :class="{ 'disabled-link': userWallet < getTotalAmount() }"
                        @click="userWallet >= getTotalAmount() ? processToPay() : null">
                        {{ $t("Subscribe") }}
                      </a> -->
                    </div>
                  </div>
                </div>

              </div>
              <div v-else class="order-items-main">
                <div class="center-card | d-flex align-items-center justify-content-center">
                  <div class="center-card-box | p-3">
                    <div class="full-btn text-center">
                      <div class="crt-img-cls">
                        <img src="@/assets/images/empty_cart.png" class="img-fluid d-inline-block" />
                      </div>
                      <p class="empty-cart my-4">{{ $t("Cart Empty") }}</p>
                      <a href="javascript:void(0)" class="w-auto d-inline-flex mx-auto" @click="goToHome()">{{ $t("Go
                        Back")}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <!--  Other tex packagine and convince and gst modal-->

          <div class="modal texes-modal fade" id="otherFeesModal" tabindex="-1" aria-labelledby="otherFeesModallabel"
            aria-hidden="true" ref="otherFeesModalRef" style="z-index: 9999">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content texes-modal-content py-4 p-3 billing-data">
                <h6 class="d-flex justify-content-center">
                  {{ $t("Taxes & Other Fees") }}
                </h6>
                <ul class="mt-4 overflow-auto">
                  <li class="d-flex justify-content-between" v-if="
                    this.$auth.setting &&
                    (this.$auth.setting.included_tax == 0 ||
                      this.$auth.setting.included_tax == 'Yes')
                  ">
                    {{
                      this.$auth.setting &&
                      this.$auth.setting.terminology &&
                      this.$auth.setting.terminology[0].tax_name
                    }}({{ this.gstMaxtax.toFixed(2) }}%)
                    <span><span v-html="$auth.setting.currency" class="currency"></span>{{ gstTotalAmount() }}</span>
                  </li>

                  <li class="d-flex justify-content-between" v-if="packagingCharge > 0">
                    <span>{{
                      this.$auth.setting.terminology[0].packaging_fee
                        ? this.$auth.setting.terminology[0].packaging_fee
                        : "Packaging Fees "
                    }}</span>
                    <span><span v-html="$auth.setting.currency" class="currency"></span>{{
                      parseFloat(packagingCharge).toFixed(2) }}</span>
                  </li>

                  <li class="d-flex justify-content-between"
                    v-if="this.getDeliveryZoneSettings.is_delivery_charge_tax_enable == 'ON' && isPickupDelivery == 1 && this.$auth.setting.is_active_porter != 'Yes' && this.$auth.setting.porter_delivery_charges != 'Yes'">
                    {{ "Delivery Charges" }} {{ this.$auth.setting.terminology[0].tax_name }} {{
                      `(${this.getDeliveryZoneSettings.delivery_charge_tax}%)` }}
                    <span><span v-html="$auth.setting.currency" class="currency"></span>{{
                      this.getDeliveryTax().toFixed(2) }} </span>
                  </li>

                  <li class="d-flex justify-content-between"
                    v-if="this.restaurantData.minimum_admin_commision !== 0 && this.restaurantData.minimum_admin_commision !== null && this.adminComissionValue !== 0">
                    {{ "Short Order fee" }}
                    <span><span v-html="$auth.setting.currency" class="currency"></span>{{
                      this.getShortFees().toFixed(2) }} </span>
                  </li>

                  <li class="d-flex justify-content-between" v-if="restaurantData.restaurant_convince_charge > 0">
                    {{
                      this.$auth.setting.terminology[0].convenience_fee
                        ? this.$auth.setting.terminology[0].convenience_fee
                        : "Convenience Fees"
                    }}
                    <span><span v-html="$auth.setting.currency" class="currency"></span>{{ getConvenienceCharge()
                      }}</span>
                  </li>
                </ul>
                <div class="modal-footer">
                  <div class="full-btn mt-4 bottom-stiky border-0 w-100" @click="closeTexModal">
                    <button>{{ $t("Got it") }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  Other tex packagine and convince and gst modal-->

          <!-- image modal preview start-->
          <div class="modal fade" id="imageModal" tabindex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="exampleModalLabel">
                    {{ itemName }}
                  </h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <img v-if="selectedImage" :src="selectedImage" class="img-fluid" alt="Image Preview" />
              </div>
            </div>
          </div>

          <!-- image modal preview end-->
          <div class="modal fade order-img" data-backdrop="static" data-keyboard="false" id="imgOpen" tabindex="-1"
            aria-labelledby="imgOpen" aria-hidden="true" ref="imgOpenModal" style="z-index: 9999">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content order-img-modal">
                <div class="modal-body text-center">
                  <a data-bs-dismiss="modal" class="lnk crosclose">X</a>
                  <img :src="imageUrl" />
                </div>
              </div>
            </div>
          </div>

          <div class="modal fade order-placed" id="freeCoupen" tabindex="-1" aria-labelledby="orderplacedLabel"
            aria-hidden="true" ref="freeCoupen" style="z-index: 9999;">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content free-coupen">
                <div class="modal-body text-center">
                  <p class="mt-3 mb-1 fs-6">
                    {{ $t("Note : The Free Order Coupon is valid for a single item only and cannot be applied to
                    multiple quantities.") }} {{ $t("Do you want to proceed ?") }}
                  </p>
                </div>
                <div class="modal-footer full-btn d-block">
                  <div class="d-flex gap-2">
                    <a href="javascript:void(0);" data-bs-dismiss="modal" @click="freePromocodeModel">{{ $t('No') }}</a>
                    <a href="javascript:void(0);" data-bs-dismiss="modal" @click="freePromocodeOrder">{{ $t('Yes')
                      }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </section>
    <subscription-modal v-if="subscriptionModalOPen" :type="subscription_type"
      @subscriptionModalClose="closeSubscriptionModal" @selectedData="manageSubscriptiopData"></subscription-modal>
    <track-order v-if="isOrderSuccess" :subscription_type="subscription_type" :iswallet="iswallet"></track-order>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import NavBar from "../components/NavBar.vue";
import DeliveryTime from "../components/DeliveryTime.vue";
import Footer from "../components/Footer.vue";
import moment from "moment";
import VueSkeletonLoader from "skeleton-loader-vue";
import noImge from "@/assets/images/place_holder.png";
import Subscription from './Subscription.vue';
import TrackOrder from '../components/TrackOrder.vue';
import Vue from "vue";
import SubscriptionModal from './SubscriptionModal.vue';

export default {
  name: 'SubscriptionCart',
  components: {
    NavBar,
    DeliveryTime,
    Footer,
    "skeleton-loader-vue": VueSkeletonLoader,
    Subscription,
    TrackOrder,
    SubscriptionModal
    // "subscription-modal": SubscriptionModal,
  },
  data() {
    return {
      everyDay_quantity: "",
      filterDayValue: [],
      subscriptionModalOPen: false,
      moment: moment,
      restaurantData: "",
      address: "",
      gstMaxtax: 0,
      cartItemCount: 1,
      promocode: "",
      promo_code_type: "",
      promoError: "",
      promoDiscount: 0,
      promoSuggetion: "",
      isPromocodeApply: false,
      isPopup: true,
      promoCashback: 0,
      promoCodeType: 0,
      contactDelivery: 0,
      onpageDelivery: 0,
      place_order_parameter: {},
      shiping_id: "",
      coupon_id: "",
      instructions: "",
      restaurantId: 0,
      scheduleDateTime: {},
      promoCodes: [],
      latitude: "",
      longitude: "",
      restaurantLat: "",
      restaurantLong: "",
      page: "review_page",
      isAsapEnable: false,
      timeFormat: "HH:mm:ss",
      isRestaurantClosed: false,
      isSameDayDelivery: false,
      is_btn_spinner: true,
      images: [],
      orderImagesId: "",
      loading: false,
      deliveryCharge: 0.0,
      noImge,
      instructionPlaceholder: "",
      deliveryTypeSlots: "",
      isDunzoAlert: false,
      packagingCharge: 0,
      driver_tip: 0,
      other_driver_tip: "",
      new_driver_tip: 0,
      is_driver_tip: false,
      radioCounts: 0,
      tipsClick: [],
      convince_charge: 0,
      rightClass: "",
      isTemplateShow: false,
      parsedDriverTips: [],
      selectedImage: null,
      itemName: "",
      // area_not_available: true,
      reason: null,
      imageUrl: "",
      is_other_Tip: false,
      others_fees: "",
      autoApplyCode: "1",
      subscriptionData: "",
      intervalDays: "",
      start_date: null,
      end_date: null,
      subscription_type: 0,
      interval: 0,
      custom_days: [],
      multiple_selection: 0,
      isOrderSuccess: false, //Success order popup
      iswallet: 0,
      openCartModal: false,
      custom_full_days: '',
      modalType: '',
      intervalValue: '',
      deliveryInstruction: [],
      Instruction: [],
      isInstruction: {},
      adminComissionValue: 0,
      deliveryChargeTax: 0,
      freePromocode: "",
      isModalConfirmed: false,
      getQuantity: 0,
      is_check_custom: true,
      customQuantity: 0
    };
  },

  beforeCreate() {
    let localCart = JSON.parse(localStorage.getItem("cart"));
    if (
      localCart &&
      localCart != "" &&
      localCart != null &&
      localCart.length > 0
    ) {
      this.$store.commit("product/setCart", localCart);
    }
    let pickupDeliveryFlag = localStorage.getItem("pickupDeliveryFlag");
    if (pickupDeliveryFlag) {
      this.$store.commit("order/pickupdelivery", pickupDeliveryFlag);
    }
  },

  mounted() {
    this.getSubscriptionData()

    if (this.$auth.setting && typeof this.$auth.setting.delivery_instruction != 'undefined') {
      this.deliveryInstruction = this.$auth.setting.delivery_instruction;
    } else {
      this.deliveryInstruction = [];
    }

    let cardTopJson = localStorage.getItem("place_order_data");
    // this.getSubscriptionItem = localStorage.getItem("subscription_cart")
    if (cardTopJson) {
      try {
        let cardTop = JSON.parse(cardTopJson);
        if (cardTop) {
          // Access properties from cardTop and assign them to Vue data properties
          if (cardTop.driver_tip) {
            this.driver_tip = cardTop.driver_tip;
            this.is_other_Tip = false;
          }
          if (cardTop.coupon_redeem) {
            this.promocode = cardTop.coupon_redeem;
          }
          if (cardTop.promo_code_type) {
            this.promoCodeType = cardTop.promo_code_type;
          }
          if (cardTop.cashback) {
            this.promoCashback = cardTop.cashback;
          }
          if (cardTop.promoDiscount) {
            this.promoDiscount = cardTop.promoDiscount;
          }
          if (cardTop.coupon_id) {
            this.coupon_id = cardTop.coupon_id;
          }
          if (cardTop.driver_tip && cardTop.is_other_Tip) {
            this.other_driver_tip = cardTop.driver_tip;
            this.is_other_Tip = true;
          }
          if (cardTop && cardTop.autoApplyCode) {
            this.autoApplyCode = cardTop.autoApplyCode;
          }
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
        // Handle the error as needed
      }
    } else {
      console.error("place_order_data is null or empty");
      // Handle the absence of place_order_data as needed
    }


    setTimeout(() => {
      if (this.$auth.user) {
        this.freePromocode = JSON.parse(localStorage.getItem('freeCode'))
        this.custom_full_days = localStorage.getItem('subscribe-custom')
        this.isTemplateShow = true;
        this.getRestaurantInfo();
        this.token = this.$auth.user.token;
        this.userId = this.$auth.user.user_id;
        this.latitude = localStorage.getItem("latitude");
        this.longitude = localStorage.getItem("longitude");
        let currentTime = moment().format(this.timeFormat);

        let routeQry = this.$route.query;
        if (routeQry.success) {
          // this.placeOrderId = routeQry.success;
          this.isOrderSuccess = true;
          // window.$(this.$refs.PlaceOrderModal).modal();
        } else if (routeQry.fail) {
          // this.isOrderfail = true; 
        }

        if (this.$auth.setting) {
          let system_start_time =
            this.$auth.setting && this.$auth.setting.system_start_time != ""
              ? this.$auth.setting.system_start_time.split(" ").pop()
              : "00:00:00";
          let system_end_time =
            this.$auth.setting && this.$auth.setting.system_end_time != ""
              ? this.$auth.setting &&
              this.$auth.setting.system_end_time.split(" ").pop()
              : "23:59:00";
          this.isSameDayDelivery =
            this.$auth.setting && this.$auth.setting.is_same_day_delivery == 0
              ? false
              : true;
          let beforeTime = moment(system_start_time, this.timeFormat);
          let afterTime = moment(system_end_time, this.timeFormat);
          if (
            moment(currentTime, this.timeFormat).isBetween(
              beforeTime,
              afterTime
            )
          ) {
            this.isAsapEnable = true;
          } else {
            this.isAsapEnable = false;
          }
        }
      } else {
        this.$router.push({ name: "sign-in" });
      }
      if (
        localStorage.getItem("orderUploadImages") != "" &&
        localStorage.getItem("orderUploadImages") != null
      ) {
        let ordImg = [];
        this.images = JSON.parse(localStorage.getItem("orderUploadImages"));
        this.images.forEach((imgid) => {
          ordImg.push(imgid.id);
        });
        this.orderImagesId = ordImg.join(",");
      }
      if (localStorage.getItem("updateAlert")) {
        this.$toasted.success(this.$t("Cart updated"), {
          duration: 2000,
        });
        localStorage.removeItem("updateAlert");
      }
    }, 600);

    if (localStorage.getItem('subscriptionModal_data')) {
      let object = JSON.parse(localStorage.getItem('subscriptionModal_data'));
      this.subscription_type = object.type;
      if (object.type == 1) {
        let everyDay_quantity = object.data[0]
        this.subscriptionData = everyDay_quantity
      }
      if (object.type == 2) {
        object.data.forEach((item) => {
          if (item.isChecked == true) {
            this.getQuantity = item.quantity
          }
        })
      }
      // if (object.type == 2) {
      //   let interval = object.data
      //   this.intervalValue = interval
      // }
      if (object.type == 3) {
        this.selectedDays = object.data
        this.customQuantity = 0
        this.selectedDays.forEach((item) => {
          if (item.isChecked == true && item.quantity > 1) {
            this.customQuantity = item.quantity
          }
        })
      }
      this.day_date_value()
    }
  },

  computed: {
    ...mapGetters("product", ["cart", "getSubscriptionItem", "getSubscription"]),
    ...mapGetters("restaurant", ["restaurantDetails"]),
    ...mapGetters("address", ["addressList", "primaryAddress"]),
    ...mapGetters("order", ["isPickupDelivery"]),
    ...mapGetters("setting", ["getsetting", "getDeliveryZoneSettings"]),
    ...mapGetters("user", ["userWallet"]),

    // canSubscribe() {
    //   if (this.freePromocode.is_free_order_item == 1) {
    //     // Check if both conditions are met (quantity <= 1 for subscriptionData and intervalDays)
    //     const subscriptionValid = !(this.subscriptionData && this.subscriptionData.quantity > 1);
    //     const intervalValid = !(this.intervalDays && this.intervalDays.quantity > 1);

    //     // Button is enabled only if both are valid
    //     return subscriptionValid && intervalValid;
    //   }
    //   // Default behavior for is_free_order_item == 0
    //   return this.userWallet >= this.getTotalAmount();
    // },

    canSubscribe() {
      // If is_free_order_item is 1, handle the specific conditions
      if (this.freePromocode.is_free_order_item == 1) {
        // Check if intervalDays.quantity > 1 or subscriptionData.quantity > 1
        const intervalExceeds = this.getQuantity > 1;
        const customQuantityExceeds = this.customQuantity > 0;
        const subscriptionExceeds = this.subscriptionData && this.subscriptionData.quantity > 1;

        if (intervalExceeds || subscriptionExceeds || customQuantityExceeds) {
          return this.userWallet >= this.getTotalAmount();
        }
        return true;
      }
      // Default behavior for is_free_order_item != 1
      return this.userWallet >= this.getTotalAmount();
    },

    getCurrentDate() {
      let currentDate = new Date();
      let year = currentDate.getFullYear();
      let month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      let day = ("0" + currentDate.getDate()).slice(-2);
      let hours = ("0" + currentDate.getHours()).slice(-2);
      let minutes = ("0" + currentDate.getMinutes()).slice(-2);
      let seconds = ("0" + currentDate.getSeconds()).slice(-2);

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },




    miniumOrderValue() {
      var value = 0;
      // if (
      //   this.restaurantDetails.minimum_order_value <=
      //   this.$auth.setting.min_order_value
      // ) {
      //   value = this.$auth.setting.min_order_value;
      // } else {
      value = this.restaurantDetails.minimum_order_value;
      // }
      return value;
    },
    checkPickupStatus() {
      return this.$auth.setting.is_pickup_enable;
    },

  },
  methods: {
    ...mapActions("product", ["removeProductFromCart"]),
    ...mapActions("address", ["getPrimaryAddress"]),
    ...mapActions("restaurant", [
      "getRestaurantDetails",
      "checkVendorRadius",
      "checkVendorMenuWithPrice",
      "getDunzoQuotationData",
    ]),
    ...mapActions("setting", ["getDeliveryZoneSetting"]),
    ...mapActions("promocode", [
      "promocodeRedeem",
      "updatePromoCode",
      "autoApplyCouponRedeem",
    ]),
    ...mapActions("order", [
      "postUploadOrderImages",
      "postremoveOrderImages",
      "getOrderDistancekm",
      "deliveryZone",
      "getSubscriptionOrder"
    ]),
    ...mapMutations("product", [
      "decrementProductInventary",
      "IncrementItemQuantity",
      "customizeCartUpdate",
      "clearCartItems",
    ]),
    ...mapMutations("order", [
      "placeOrderData",
      "clearPlaceOrderData",
      "pickupdelivery",
    ]),


    // day_date_value() {
    //   if (this.subscription_type == 3) {
    //     this.filterDayValue.length = 0;
    //     this.filterDayValue = this.selectedDays.map((day) => ({
    //       ...day,
    //       day: day.day,
    //       quantity: day.isChecked ? day.quantity : 0,
    //     }));
    //   }
    //   return null;
    // },


    day_date_value() {
      if (this.subscription_type == 3) {
        this.filterDayValue = (this.selectedDays || []).map((day) => ({
          ...day,
          day: day.day,
          quantity: day.isChecked ? day.quantity : 0,
        }));
      }
      return null;
    },


    async getAllDeliveryZoneSeeting(latitude, longitude) {
      if (!latitude || !longitude) {
        latitude = this.latitude;
        longitude = this.longitude;
      }
      try {
        const data = await this.getDeliveryZoneSetting({
          vendor_id: this.$auth.getVendorId(),
          is_language: this.$store.state.lang.locale,
          lat: latitude,
          lan: longitude,
          // order_web: host
        });
        if (
          data.Result.is_delivery_zone_available == null ||
          data.Result.is_delivery_zone_available == "No"
        ) {
          this.$swal({
            title: this.$auth.setting.app_name,
            html: data.Result.not_available_delivery_zone_reason,
          });
          if (data.code == 101) {
            this.$swal({
              title: this.$auth.setting.app_name,
              html: "Sorry, At this movement we are not providing delivery service at your selected location",
            });
          }
          return false;
        }
        return false;
      } catch (error) {
        console.error("Error in getAllDeliveryZoneSetting:", error);
      }
    },
    openImagePreview(image, name) {
      this.selectedImage = image;
      this.itemName = name;
    },

    closeSubscriptionModal(val) {
      let object = JSON.parse(localStorage.getItem('subscriptionModal_data'))
      this.filterDayValue = object.data
      this.subscriptionModalOPen = false;
      this.intervalDays = JSON.parse(localStorage.getItem("setIntervalday"));
    },

    // manageSubscriptiopData(val) {
    //   if (this.subscription_type == 1) {
    //     this.everyDay = val
    //   } else if (this.subscription_type == 2) {
    //     this.interval = val;
    //     const selectedInterval = this.interval.find(item => item.isChecked === true);
    //     this.intervalDays = selectedInterval ? selectedInterval : { quantity: 0, label: '' };
    //     // localStorage.setItem('intervalDay', JSON.stringify(this.intervalDay))
    //   } else if (this.subscription_type == 3) {
    //     this.selectedDays = val
    //     this.day_date_value();
    //   } else {
    //   }
    // },
    manageSubscriptiopData(val) {
      if (this.subscription_type == 1) {
        this.everyDay_quantity = val;
        // this.addTOSubscription(this.everyDay_quantity[0])
        if (Array.isArray(this.everyDay_quantity) && this.everyDay_quantity.length > 0) {
          this.subscriptionData = this.everyDay_quantity[0];
          localStorage.setItem('subscriptionObj', JSON.stringify(this.subscriptionData))
        }
        // this.subscriptionData = this.everyDay_quantity[0];
        // localStorage.setItem('subscriptionObj', JSON.stringify(this.subscriptionData))
      } else if (this.subscription_type == 2) {
        let intervalDate = val;
        const selectedInterval = intervalDate && intervalDate.find(item => item.isChecked == true);
        this.intervalDays = selectedInterval && selectedInterval.value
        this.intervalDays = selectedInterval ? selectedInterval : { quantity: 0, label: '' };
        this.getQuantity = this.intervalDays.quantity
        this.subscriptionData.quantity = 1
        // localStorage.setItem('intervalDay', JSON.stringify(this.intervalDays));
      } else if (this.subscription_type == 3) {
        this.selectedDays = val;
        // free promocode apply
        this.customQuantity = 0;
        this.selectedDays && this.selectedDays.forEach((item) => {
          if (item.isChecked == true && item.quantity > 1) {
            this.customQuantity = item.quantity
          }
        })
        this.day_date_value();
      }
      this.getDeliveryChrge()
      this.getDeliveryLocation()
      this.getTotalItemAmount()
    },


    SubModals() {
      let data = JSON.parse(localStorage.getItem("subscription_cart"))
      // this.intervalDays = data[0].quantity
      // this.subscription_type = data[0].subscription_type;
      this.subscription_type = this.subscription_type;
      this.subscriptionModalOPen = true;
      // this.day_date_value();
      this.manageSubscriptiopData();
    },

    //save your item price
    savedPrice() {
      let savemrpTotal = 0;
      let savemrp = 0;
      this.getSubscriptionItem.forEach((item) => {
        if (item.price < item.mrp) {
          savemrp = item.mrp - item.price;
          savemrpTotal += savemrp * item.quantity;
        }
      });
      return savemrpTotal;
    },
    redirectBillingData() {
      var myDiv = document.getElementById("billing-data");
      var offsetTop = myDiv.offsetTop;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    },
    deliveryModalOpen() {
      this.driver_tip = 0;
      this.promocode = "";
      // this.promoSuggetion = "";
      this.isPromocodeApply = false;
      this.promoDiscount = 0;
      this.promoCashback = 0;
      // this.promocodeType = 0;
      // localStorage.removeItem('promocodeType');
      $(".location-modal").addClass("heightModal");
      $("#deliveryModal").modal("show");
    },

    openImg(element) {
      this.imageUrl = element.image;
      $("#imgOpen").modal("show");
    },

    //Item Text Display
    itemTexDisplay() {
      let itemTex = 0;
      this.getSubscriptionItem.forEach((item) => {
        itemTex += item.item_tax * item.quantity;
      });
      return itemTex.toFixed(2);
    },

    // auto apply promocode by defult apply code

    onSetOrderTime(value) {
      this.scheduleDateTime = value;
    },

    // async getAddress(lat1, lon1, lat2, lon2) {
    //   // localStorage.removeItem('promocodeType');
    //   let response = await this.getAllDeliveryZoneSeeting(
    //     this.primaryAddress.latitude,
    //     this.primaryAddress.longitude
    //   );
    //   if (response) {
    //     this.$swal({
    //       title: this.$auth.setting.app_name,
    //       // html: this.getDeliveryZoneSettings.not_available_delivery_zone_reason,
    //       html: "Sorry, At this movement we are not providing delivery service at your selected location",
    //     });
    //     return true;
    //   }

    //   if (Object.keys(this.primaryAddress).length != 0) {
    //     this.is_btn_spinner = false;
    //     this.address =
    //       this.primaryAddress.flat_no +
    //       " " +
    //       this.primaryAddress.house_name +
    //       " " +
    //       this.primaryAddress.Shiping_address;
    //     this.shiping_id = this.primaryAddress.shiping_id;

    //     await this.getDeliveryChrge();
    //   } else {
    //     this.address = "";
    //     this.is_btn_spinner = false;
    //   }

    //   if (
    //     Object.keys(this.primaryAddress).length == 0 ||
    //     this.primaryAddress.deliveryAddress == ""
    //   ) {
    //     this.$router.push({ name: "add-address" });
    //     // this.deliveryModalOpen();
    //     // // if(this.addressList.length > 0){
    //     // // }else{
    //     // }
    //   }
    // },


    //Get Restaurant Information


    async getAddress(lat1, lon1, lat2, lon2) {
      // localStorage.removeItem('promocodeType');
      let response = await this.getAllDeliveryZoneSeeting(this.primaryAddress.latitude, this.primaryAddress.longitude)
      if (response) {
        this.$swal({
          title: this.$auth.setting.app_name,
          // html: this.getDeliveryZoneSettings.not_available_delivery_zone_reason,
          html: "Sorry, At this movement we are not providing delivery service at your selected location",
        });
        return true;
      }


      if (Object.keys(this.primaryAddress).length != 0) {
        this.is_btn_spinner = false;
        this.address =
          this.primaryAddress.flat_no +
          " " +
          this.primaryAddress.house_name +
          " " +
          this.primaryAddress.Shiping_address;
        this.shiping_id = this.primaryAddress.shiping_id;
        await this.getDeliveryChrge();
        // this.autoApplyPromo()
      } else {
        this.address = "";
        this.is_btn_spinner = false;
      }

      if (Object.keys(this.primaryAddress).length == 0 || this.primaryAddress.deliveryAddress == '') {
        this.$router.push({ name: "add-address" });
        // this.deliveryModalOpen();
        // // if(this.addressList.length > 0){  
        // // }else{
        // }
      }
    },


    getRestaurantInfo() {
      if (this.getSubscriptionItem.length) {
        this.restaurantId = this.getSubscriptionItem[0].restaurant_id;
        this.getRestaurantDetails({
          vendor_id: this.$auth.getVendorId(),
          restaurant_id: this.restaurantId,
          is_langauge: this.$store.state.lang.locale,
          latitude: parseFloat(localStorage.getItem("latitude")),
          longitude: parseFloat(localStorage.getItem("longitude")),
          delivery_type_time_slots: this.isPickupDelivery,
        }).then((data) => {
          if (data.code == 200) {
            this.restaurantData = data.Result;
            this.restaurant_service_tax = data.Result.restaurant_service_tax;
            this.restaurantLat = data.Result.latitude;
            this.restaurantLong = data.Result.longitude;
            this.deliveryTypeSlots = data.Result.delivery_type_time_slots;
            this.instructionPlaceholder =
              this.$t("any special request for ") + "Restaurants";
            this.isRestaurantClosed =
              data.Result.restaurant_on_off == "0" ? false : true;
            this.getDeliveryChrge();
            this.getShortFees()
          }
        });
      }
    },

    getShortFees() {
      if (this.restaurantData.admin_commision_type == 'commission_in' && this.restaurantData.minimum_admin_commision !== 0 && this.restaurantData.minimum_admin_commision !== null) {
        this.adminComissionValue = this.restaurantData.minimum_admin_commision - this.restaurantData.admin_commision
      }
      if (this.restaurantData.admin_commision_type == 'commission_in_percentage') {
        // Calculate the percentage commission based on getTotalItemAmount
        // const percentageCommission = ((this.getTotalItemAmount() * this.restaurantData.admin_commision) / 100).toFixed(2);
        const percentageCommission = (this.getTotalItemAmount() * this.restaurantData.admin_commision) / 100;
        // return ((this.getTotalItemAmount() * gstServiceTax) / 100).toFixed(2);
        if (percentageCommission > this.restaurantData.minimum_admin_commision) {
          this.adminComissionValue = 0;
        }
        var shortOrderFee = this.restaurantData.minimum_admin_commision - percentageCommission;
        shortOrderFee = Math.round(shortOrderFee * 100) / 100;

        if (shortOrderFee > 0) {
          this.adminComissionValue = shortOrderFee;
        }
      }
      return this.adminComissionValue;
    },

    getSubscriptionData() {
      this.subscriptionData = JSON.parse(localStorage.getItem("subscriptionObj"));
      this.intervalDays = JSON.parse(localStorage.getItem("setIntervalday"));
    },

    getDeliveryInstruction(value, type, id) {
      const index = this.Instruction.findIndex(instruction => instruction.id === id);
      if (index !== -1) {
        this.Instruction.splice(index, 1);
        this.$set(this.isInstruction, id, false);
      } else {
        this.Instruction.push({ id: id, value: value });
        this.$set(this.isInstruction, id, true);
      }
      //localStorage.setItem('selectedInstruction', JSON.stringify(this.Instruction));
    },


    //Display Total Amount Of item cart
    getTotalItemPrice(item) {
      return item.price * item.quantity;
    },

    //Get Total Amount of Items
    getTotalItemAmount() {
      let itmTotal = 0;
      let itemCount = 0;
      let packCharge = 0;
      this.getSubscriptionItem.forEach((item) => {
        itmTotal += item.price * item.quantity;
        itemCount++;
        packCharge += item.packaging_charge * item.quantity;
      });
      let restPackCharge = 0;
      if (
        this.restaurantData.restaurant_packaging_type &&
        this.restaurantData.restaurant_packaging_type == "fixed" &&
        this.restaurantData.restaurant_packaging_charge > 0
      ) {
        restPackCharge = parseFloat(
          this.restaurantData.restaurant_packaging_charge
        );
      } else if (
        this.restaurantData.restaurant_packaging_type &&
        this.restaurantData.restaurant_packaging_type == "percentage" &&
        this.restaurantData.restaurant_packaging_charge > 0
      ) {
        restPackCharge = parseFloat(
          parseFloat(
            (
              (itmTotal / 100) *
              this.restaurantData.restaurant_packaging_charge
            ).toFixed(2)
          )
        );
      }
      this.packagingCharge = packCharge + restPackCharge;
      this.cartItemCount = itemCount;
      return parseFloat(itmTotal).toFixed(2);
    },

    //Increament Item
    plusItem(item, index) {
      //const cartItemIndex = this.cart.findIndex(itm => parseInt(itm.item_id) === parseInt(item.item_id));
      let cartItem = { cartItemIndex: index, product: item };
      if (item.itemTotalQuantity > item.quantity) {
        this.$store.commit("product/IncrementItemQuantity", cartItem);
        this.isPopup = false;
        this.getShortFees()
        this.getDeliveryChrge();
      } else {
        alert(this.$t("Maximum items reached"));
      }
    },

    //Decrement Item
    minusItem(cartProduct, index) {
      if (cartProduct.quantity == "1" || cartProduct.quantity == "0") {
        this.deleteProduct(index);
      } else {
        this.getShortFees()
        this.isPopup = false;
        this.$store.commit("product/decrementProductInventary", index);
        this.getDeliveryChrge();
      }
    },

    //Remove Item from cart
    deleteProduct(index) {
      this.$store.commit("order/clearPlaceOrderData");
      this.$store.dispatch("product/removeProductFromCart", index);
    },

    //GST Total Amount Display
    gstTotalAmount() {
      if (
        this.$auth.setting &&
        (this.$auth.setting.included_tax == 0 ||
          this.$auth.setting.included_tax == "Yes")
      ) {
        let gstServiceTax = parseFloat(
          this.restaurantDetails.restaurant_service_tax
        );
        this.gstMaxtax = gstServiceTax;
        return ((this.getTotalItemAmount() / 100) * gstServiceTax).toFixed(2);
      } else {
        return 0;
      }
    },

    getConvenienceCharge() {
      if (this.restaurantData.restaurant_convince_type == "fixed") {
        return (this.convince_charge =
          this.restaurantData.restaurant_convince_charge.toFixed(2));
      } else {
        return (this.convince_charge = (
          (this.getTotalItemAmount() *
            this.restaurantData.restaurant_convince_charge) /
          100
        ).toFixed(2));
      }
    },

    // Delivery Charge Tex Amount Display
    getDeliveryTax() {
      if (this.getDeliveryZoneSettings.is_delivery_charge_tax_enable == 'ON' && this.$auth.setting.is_active_porter != "Yes" && this.$auth.setting.porter_delivery_charges != "Yes") {
        const taxPercentage = this.getDeliveryZoneSettings.delivery_charge_tax;
        this.deliveryChargeTax = (this.deliveryCharge * taxPercentage) / 100;
      }
      return this.deliveryChargeTax
    },

    //Delivery Charge Total Amount Display
    getDeliveryChrge() {
      if (
        this.$auth.setting.third_party_delivery_services &&
        this.$auth.setting.third_party_delivery_services.length == 1 &&
        this.$auth.setting.third_party_delivery_services.includes("dunzo")
      ) {
        this.is_btn_spinner = true;
        this.getDunzoQuotationData({
          address_id: this.shiping_id,
          is_langauge: this.$store.state.lang.locale,
          restaurant_id: this.restaurantId,
          vendor_id: this.$auth.getVendorId(),
        }).then((data) => {
          if (data.code == 200) {
            this.deliveryCharge = data.Result.estimated_price;
            this.isDunzoAlert = false;
            this.is_btn_spinner = false;
          } else {
            this.isDunzoAlert = true;
            this.$swal({
              title: this.restaurantData.name,
              html: "Sorry, At this movement we are not providing delivery service at your selected location.",
            });
            this.is_btn_spinner = false;
          }
        });
      } else {
        let vardeliveryCharge = 0;
        if (this.isPickupDelivery == 1) {
          // this.driver_tip = 0;
          // this.new_driver_tip = 0;
          let clntLatlng = this.primaryAddress;
          let host =
            window.location.hostname == "localhost"
              ? "dev.deonde.co"
              : window.location.hostname;
          this.deliveryZone({
            vendor_id: this.$auth.getVendorId(),
            is_langauge: this.$store.state.lang.locale,
            latitude: clntLatlng.latitude,
            longitude: clntLatlng.longitude,
            order_web: host,
          }).then((data) => {
            if (data.settings.delivery_charges_type == "Distance") {
              let unit = "K";
              if (data.settings.distance_metric == "Miles") {
                unit = "N";
              }
              let clntLatlng = this.primaryAddress;
              let restLatlng = this.restaurantDetails;
              if (
                clntLatlng.latitude != undefined &&
                clntLatlng.longitude != undefined &&
                restLatlng.latitude != undefined &&
                restLatlng.longitude != undefined
              ) {
                this.getDeliveryLocation(
                  clntLatlng.latitude,
                  clntLatlng.longitude,
                  restLatlng.latitude,
                  restLatlng.longitude,
                  unit
                );
                // this.driver_tip = 0;
                // this.new_driver_tip = 0;
              }
              // this.promoDiscount = 0
              this.parsedDriverTips = this.getDeliveryZoneSettings.driver_tip;
            } else if (data.settings.delivery_charges_type == "Amount") {
              let checkValue = false;
              this.parsedDriverTips = this.getDeliveryZoneSettings.driver_tip;

              const totalAmount = parseInt(this.getTotalItemAmount());
              const chargeInRange = data.settings.charges.find((charge) => {
                return (
                  charge.min_value <= totalAmount &&
                  totalAmount <= charge.max_value
                );
              });
              if (chargeInRange) {
                this.deliveryCharge = chargeInRange.delivery_charges;
                localStorage.setItem("deliveryCharges", this.deliveryCharge);
              } else {
                const lastChargeIndex = data.settings.charges.length - 1;
                if (
                  parseInt(data.settings.charges[0].min_value) >=
                  parseInt(totalAmount)
                ) {
                  this.deliveryCharge =
                    data.settings.charges[0].delivery_charges;
                } else if (
                  data.settings.charges[lastChargeIndex].max_value <=
                  totalAmount
                ) {
                  this.deliveryCharge =
                    data.settings.charges[lastChargeIndex].delivery_charges;
                }
              }
            }
          });
        } else {
          // this.driver_tip = 0;
          this.new_driver_tip = 0;
          vardeliveryCharge = 0;
        }
        // this.deliveryCharge = vardeliveryCharge.toFixed(2);
      }
    },

    //Get Delivery Location Distance Find
    getDeliveryLocation(lat1, lon1, lat2, lon2, unit) {
      if (this.$auth.setting) {
        let dist = 0;
        let deliveryCharge = [];
        let clntLatlng = this.primaryAddress;
        let host = window.location.hostname;
        deliveryCharge = this.getDeliveryZoneSettings.charges;
        if (
          lat1 != undefined &&
          lon1 != undefined &&
          lat2 != undefined &&
          lon2 != undefined
        ) {
          this.getOrderDistancekm({
            vendor_id: this.$auth.getVendorId(),
            useraddress_latitude: lat1,
            useraddress_longitude: lon1,
            store_latitude: lat2,
            store_longitude: lon2,
          }).then((data) => {
            if (data.code == 200) {
              let distancekm = data.Result.distance_km;
              let checkKM = false;
              deliveryCharge.forEach((charge) => {
                // let promocodeType = JSON.parse(localStorage.getItem('promocode'))
                // this.driver_tip = 0
                if (
                  distancekm > charge.min_value &&
                  distancekm <= charge.max_value
                ) {
                  dist = charge.delivery_charges;
                  checkKM = true;
                  this.deliveryCharge = parseFloat(dist);
                  localStorage.setItem("deliveryCharges", this.deliveryCharge);
                  let currency = "";
                  if (
                    document.getElementById("currency") &&
                    document.getElementById("currency").childNodes[0]
                  ) {
                    currency =
                      document.getElementById("currency").childNodes[0]
                        .textContent;
                  }
                }
              });
              if (!checkKM) {
                // dist = this.$auth.setting.charges[0].delivery_charges
                // Using array length to get the last index value
                const lastChargeIndex =
                  this.getDeliveryZoneSettings.charges.length - 1;
                const lastDeliveryCharge =
                  this.getDeliveryZoneSettings.charges[lastChargeIndex]
                    .delivery_charges;
                dist = distancekm == 0.0 ? "0.0" : lastDeliveryCharge;
                this.deliveryCharge = parseFloat(dist);
              }
              this.deliveryCharge = parseFloat(dist);
            } else {
              this.deliveryCharge = 0;
            }
          });
        } else {
          this.deliveryCharge = 0;
          // this.driver_tip = 0;
        }
      }
    },

    //Grand Total Amount Display
    getTotalAmount() {
      let gstTotalPrice = 0;
      let convenienceCharge = 0;
      let driverTip = 0;
      if (
        this.$auth.setting &&
        (this.$auth.setting.included_tax == 0 ||
          this.$auth.setting.included_tax == "Yes")
      ) {
        gstTotalPrice = parseFloat(this.gstTotalAmount());
      }
      if (
        this.restaurantData.restaurant_convince_type != "" &&
        this.restaurantData.restaurant_convince_charge > 0
      ) {
        convenienceCharge = parseFloat(this.getConvenienceCharge());
      }
      if (this.driver_tip == 0 && this.new_driver_tip > 0) {
        driverTip = parseFloat(this.other_driver_tip);
      } else {
        driverTip = parseFloat(this.driver_tip);
      }

      let totalItemPrice = parseFloat(this.getTotalItemAmount());
      let deliveryTotalPrice = parseFloat(this.deliveryCharge);

      this.others_fees =
        gstTotalPrice + convenienceCharge + this.packagingCharge + this.getDeliveryTax() + this.adminComissionValue;

      if (this.promoCodeType == 5) {
        let grandTotal = (
          totalItemPrice +
          driverTip -
          this.promoDiscount +
          this.others_fees
        ).toFixed(2);
        return grandTotal;
      } else {
        let grandTotal = (
          totalItemPrice +
          deliveryTotalPrice +
          driverTip -
          this.promoDiscount +
          this.others_fees
        ).toFixed(2);
        return grandTotal;
      }

      // let grandTotal = (
      //   totalItemPrice +
      //   deliveryTotalPrice +
      //   driverTip -
      //   this.promoDiscount +
      //   this.others_fees
      // ).toFixed(2);
      // return grandTotal;
    },

    //Apply Promocode For function


    //Remove Apply Code
    removePromocode() {
      localStorage.removeItem("place_order_data");
      localStorage.removeItem("promocodeType");
      this.promocode = "";
      this.promoSuggetion = "";
      this.isPromocodeApply = false;
      this.promoDiscount = 0;
      this.promoCashback = 0;
      this.promoCodeType = 0;
    },

    comapreDate(d1, d2) {
      let dateTime1 = new Date(d1);
      let dateTime2 = new Date(d2);

      let time1 =
        dateTime1.getHours() * 60 * 60 * 1000 +
        dateTime1.getMinutes() * 60 * 1000 +
        dateTime1.getSeconds() * 1000 +
        dateTime1.getMilliseconds();
      let time2 =
        dateTime2.getHours() * 60 * 60 * 1000 +
        dateTime2.getMinutes() * 60 * 1000 +
        dateTime2.getSeconds() * 1000 +
        dateTime2.getMilliseconds();
      if (time1 < time2) {
        return true;
      } else {
        return false;
      }
    },


    // checkSubscriptionData() {
    //   // Retrieve data from localStorage
    //   const dataString = localStorage.getItem("subscriptionModal_data");

    //   // Parse the data
    //   if (!dataString) return false; // Return false if no data is found

    //   try {
    //     const subscriptionData = JSON.parse(dataString);

    //     // Check if the type is 3 and if all conditions are met
    //     if (
    //       subscriptionData.type === 3 &&
    //       subscriptionData.data.every(
    //         (item) => item.isChecked === true && item.quantity === 1
    //       )
    //     ) {
    //       return true; // All conditions are satisfied
    //     } else {
    //       return false; // Some conditions are not met
    //     }
    //   } catch (error) {
    //     console.error("Error parsing subscriptionModal_data:", error);
    //     return false; // Return false if data is invalid
    //   }
    // },

    freePromocodeModel() {
      $("#freeCoupen").modal("hide");
    },

    freePromocodeOrder() {
      this.isModalConfirmed = true; // Set the modal confirmation state
      this.processToPay(); // Call the payment process again
    },

    async processToPay() {
      // this.is_check_custom = this.checkSubscriptionData();
      // Business Operation Hours check a time  start time and end time == start
      if (this.userWallet < this.getTotalAmount() && this.subscriptionData && (this.subscriptionData.quantity > 1 || this.getQuantity > 1 || this.customQuantity > 0)) {
        Vue.toasted.error("Insufficient wallet amount please recharge your wallet", {
          duration: 2000,
        });
        return;
      }
      // return false
      const currentYear = new Date().getFullYear();
      const systemStartYear = new Date(
        this.$auth.setting.system_start_time
      ).getFullYear();
      const systemEndYear = new Date(
        this.$auth.setting.system_end_time
      ).getFullYear();

      if (!(currentYear >= systemStartYear && currentYear <= systemEndYear)) {
        // Display a message or perform an action if the current year is outside the range
        this.$swal({
          title: this.$auth.setting.app_name,
          html: "The Restaurants has closed now.<br/>Please come back later",
        });
        return true;
      }

      // if (!(this.comapreDate(this.$auth.setting.system_start_time, this.getCurrentDate) && this.comapreDate(this.getCurrentDate, this.$auth.setting.system_end_time))) {
      //   this.$swal({
      //     title: this.$auth.setting.app_name,
      //     html: 'The Restaurants has closed now.<br/>Please come back later',
      //   });
      //   return true;
      // }

      // if ((this.comapreDate(this.getCurrentDate, this.$auth.setting.system_end_time))) {
      //   this.$swal({
      //     title: this.$auth.setting.app_name,
      //     html: 'The restaurant has closed now.<br/>Please come back later',
      //   });
      //   return true;
      // }

      // if (this.comapreDate(this.$auth.setting.system_start_time,) && this.comapreDate(this.getCurrentDate, this.$auth.setting.system_end_time)) {
      //   this.$swal({
      //     title: this.$auth.setting.app_name,
      //     html: 'The Restaurants has closed now.<br/>Please come back later 111',
      //   });
      //   return false
      // }
      // Business Operation Hours check a time  start time and end time  == end

      // if (
      //   this.getDeliveryZoneSettings.is_delivery_zone_available == null ||
      //   this.getDeliveryZoneSettings.is_delivery_zone_available == "No"
      // ) {
      //   this.$swal({
      //     title: this.$auth.setting.app_name,
      //     html: this.getDeliveryZoneSettings.not_available_delivery_zone_reason,
      //   });
      //   return true;
      // }

      // check free order promocode
      if (this.freePromocode.is_free_order_item == 1) {
        if (this.subscriptionData && (this.subscriptionData.quantity > 1 && !this.isModalConfirmed || this.getQuantity > 1 || this.customQuantity > 0)
        ) {
          // Show the modal if quantity is greater than 1
          $("#freeCoupen").modal("show");
          return;
        }
        this.isModalConfirmed = false;
      }


      let response = await this.getAllDeliveryZoneSeeting(
        this.latitude,
        this.longitude
      );

      if (response == undefined || response) {
        this.$swal({
          title: this.$auth.setting.app_name,
          html: "Sorry, At this movement we are not providing delivery service at your selected location.<br> <b>Please change address</b>",
        });
        return false;
      }

      if (this.isPickupDelivery == 1 && this.isPickupDelivery == -1) {
        // if (this.shiping_id == "") {
        this.$toasted.error(this.$t("Please select or add address"), {
          duration: 2000,
        });
        return false;
        // }
      }

      // if takeaway option admin site off this showing
      // if (this.$auth.setting.is_pickup_enable == 'false') {
      //   this.$swal({
      //     title: this.$auth.setting.app_name,
      //     html: "Can't delivery to  your location.Sorry Takeaway option close now.",
      //   });
      //   return false;
      // }

      this.is_btn_spinner = true;

      // if (
      //   !this.isAsapEnable ||
      //   !this.isRestaurantClosed ||
      //   !this.isSameDayDelivery
      // ) {
      //   if (
      //     !this.scheduleDateTime.time ||
      //     this.scheduleDateTime.time == "" ||
      //     this.scheduleDateTime.time == "asap"
      //   ) {
      //     this.$refs.DeliveryTime.openTime();
      //     this.is_btn_spinner = false;
      //     return false;
      //   }
      // }

      if (this.$auth.user) {
        let itemDetailsArray = [];
        let itemDetails = {};
        this.getSubscriptionItem.forEach((item) => {
          let singleSelection = "";
          // let multiple_selection = "";

          this.start_date = item.start_date;
          this.end_date = item.end_date;
          this.subscription_type = item.subscription_type;
          this.interval = item.interval;
          // this.custom_days = item.custom_days;
          this.custom_days = item.custom_days;

          this.multiple_selection = item.selectCustoizeID.join();
          itemDetails = {
            item_id: item.item_id,
            amount: parseFloat(this.getTotalItemPrice(item)).toFixed(2),
            single_selection: singleSelection,
            is_bravges: "0",
            multiple_selection: this.multiple_selection,
            quantity: item.quantity,
            packaging_charge: parseFloat(this.packagingCharge).toFixed(2),
          };
          itemDetailsArray.push(itemDetails);
        });



        // this.getSubscriptionOrder(() => {

        // })

        let future_delivery_date = "";

        if (this.scheduleDateTime) {
          if (
            this.scheduleDateTime.time &&
            this.scheduleDateTime.time != "asap"
          ) {
            //Schedule order
            future_delivery_date =
              this.scheduleDateTime.date + " " + this.scheduleDateTime.time;
          }
        }

        let deliveryType = "Delivery";
        if (this.isPickupDelivery == 1 || this.isPickupDelivery == -1) {
          deliveryType = "Delivery";
        } else {
          deliveryType = "Pickup";
        }
        // return false
        let costumDays = JSON.parse(localStorage.getItem('subscribe-custom'));
        this.place_order_parameter = {
          address_id: this.primaryAddress.shiping_id,
          app_version_code: "",
          contact_less_delivery: this.contactDelivery,
          custom_days: this.subscription_type === 3 ? JSON.stringify(costumDays) : [],
          // custom_days: this.selectedDays,
          // custom_days: this.custom_days,
          delivery_fee: this.deliveryCharge,
          device_type: "Web",
          driver_tip: "0",
          end_date: this.end_date,
          // interval: this.subscription_type === 1 ? "1" : this.subscription_type === 2 ? 2 : this.subscription_type === 3 ? 0 : null,
          interval: this.subscription_type === 1 || this.subscription_type === 3 ? this.interval : this.intervalDays.value,
          is_langauge: this.$store.state.lang.locale,
          multiple_selection: this.multiple_selection,
          itemDetails: itemDetailsArray,
          restaurant_item_details: JSON.stringify(itemDetailsArray),
          notes: this.instructions,
          payment_method: "Wallet",
          payment_type: "wallet",
          restaurant_id: this.restaurantData.restaurant_id,
          restaurant_service_fee: this.gstMaxtax,
          service_fee: this.gstTotalAmount(),
          start_date: this.start_date,
          sub_total:
            parseFloat(this.getTotalItemAmount()) +
            parseFloat(this.deliveryCharge) +
            parseFloat(this.gstTotalAmount()) +
            parseFloat(this.packagingCharge.toFixed(2)) +
            parseFloat(this.convince_charge) +
            parseFloat(this.getDeliveryTax()) +
            parseFloat(this.getShortFees()) +
            parseFloat(
              this.new_driver_tip > 0 ? this.other_driver_tip : this.driver_tip
            ), //this.getTotalAmount(),
          // customDays: JSON.parse(this.custom_days),
          subscription_type: this.subscription_type,
          total_amount: this.getTotalAmount(),
          user_id: this.userId,
          vendor_id: this.$auth.getVendorId(),
          wallet_amount: this.userWallet,
          delivery_instruction: JSON.stringify(this.Instruction),
          short_order_fee: this.getShortFees().toFixed(2),
          delivery_charges_tax: this.getDeliveryTax().toFixed(2),
          delivery_charges_tax_percentage: this.getDeliveryZoneSettings.delivery_charge_tax,
          is_wallet_check: this.subscriptionData.quantity > 1 || this.getQuantity > 1 ? true : false,
          // SGST: 0.0,
          // sub_total: this.getTotalItemAmount(),
          // suggestion: "",
          // transaction_id: "",
          // orderImagesid: this.orderImagesId,
          // saved_amount:
          //   parseFloat(this.savedPrice()) + parseFloat(this.promoDiscount),
          // item_tax: this.itemTexDisplay(),
          // packaging_charge: parseFloat(this.packagingCharge).toFixed(2),
          // convince_charge: this.convince_charge,
          // userPrimaryAddress: this.primaryAddress,
        };

        this.getSubscriptionOrder((this.place_order_parameter)).then((data) => {
          if (data.code == 200) {
            this.isOrderSuccess = true;
          }
        })

        if (this.isPickupDelivery == 1 || this.isPickupDelivery == -1) {
          await this.checkRestaurantRadius();
        } else {
          await this.checkRestaurantMenuWithPrice();
        }
      } else {
        this.$router.push({ name: "sign-in" });
      }
    },


    checkRestaurantRadius() {
      let vm = this;
      this.checkVendorRadius({
        is_langauge: this.$store.state.lang.locale,
        latitude:
          this.primaryAddress.latitude && this.primaryAddress.latitude == ""
            ? this.primaryAddress.latitude
            : parseFloat(localStorage.getItem("latitude")),
        longitude:
          this.primaryAddress.longitude && this.primaryAddress.longitude == ""
            ? this.primaryAddress.longitude
            : parseFloat(localStorage.getItem("longitude")),
        restaurant_id: this.restaurantId,
        vendor_id: this.$auth.getVendorId(),
      }).then((data) => {
        if (data.code == 200) {
          this.checkRestaurantMenuWithPrice();
        } else if (this.restaurantDetails.restaurant_on_off == null) {
          this.$swal({ title: this.$t("This Restaurant is closed") });
          this.is_btn_spinner = false;
          return false;
        } else {
          this.$swal({
            title: this.$t(
              "Oops! We do not deliver at your selected location "
            ),
          });
          this.is_btn_spinner = false;
          return false;
        }
      })
        .catch(function () {
          vm.$swal({ title: vm.$t("Something went wrong, Please try again") });
          vm.is_btn_spinner = false;
          return false;
        });
    },

    checkRestaurantMenuWithPrice() {
      let menu_item_ids = [];
      let menu_item_price = [];
      let customisation_menu_item_ids = [];
      let customisation_menu_item_price = [];
      let menu_item_quantity = [];
      let item_packaging_charge = [];

      this.getSubscriptionItem.forEach((item) => {
        menu_item_ids.push(item.item_id);
        menu_item_price.push(item.item_price);
        menu_item_quantity.push(item.quantity);
        item_packaging_charge.push(item.packaging_charge);
        if (item.selected_type.length) {
          customisation_menu_item_ids.push(item.selectCustoizeID);
          customisation_menu_item_price.push(item.selectCustoizePrice);
        }
      });

      let postdata = {
        is_langauge: this.$store.state.lang.locale,
        vendor_id: this.$auth.getVendorId(),
        restaurant_id: this.restaurantId,
        menu_item: menu_item_ids.join(),
        item_price: menu_item_price.join(),
        customisation_id: customisation_menu_item_ids.join(),
        customisation_price: customisation_menu_item_price.join(),
        item_quantity: menu_item_quantity.join(),
        beverage_ids: "",
        packaging_charge: item_packaging_charge.join(),
      };
      this.checkVendorMenuWithPrice(postdata).then((data) => {
        if (data.code == 200) {
          this.is_btn_spinner = false;
          this.$store.dispatch(
            "order/placeOrderData",
            this.place_order_parameter
          );
          localStorage.setItem(
            "place_order_data",
            JSON.stringify(this.place_order_parameter)
          );
          // this.$refs.redirectPayment.$el.click();
        } else if (data.code == 205) {
          this.is_btn_spinner = false;
          data.Result.forEach((updateData) => {
            const cartindex = this.getSubscriptionItem.findIndex(
              (item) => parseInt(item.item_id) === parseInt(updateData.item_id)
            );
            let itemTax = 0;
            if (updateData.quantity == 0) {
              this.getSubscriptionItem.splice(cartindex, 1);
            } else {
              if (updateData.item_tax != "0") {
                itemTax =
                  updateData.price -
                  updateData.price / (1 + updateData.item_tax / 100);
              }
              this.getSubscriptionItem[cartindex].item_tax = itemTax;
              this.getSubscriptionItem[cartindex].item_price = updateData.price;
              this.getSubscriptionItem[cartindex].mrp = updateData.mrp;
              this.getSubscriptionItem[cartindex].packaging_charge = parseFloat(
                updateData.packaging_charge
              );
              if (
                updateData.quantity <
                this.getSubscriptionItem[cartindex].quantity
              ) {
                this.getSubscriptionItem[cartindex].quantity =
                  updateData.quantity;
                this.getSubscriptionItem[cartindex].itemTotalQuantity =
                  updateData.quantity;
              }
            }
          });
          /****Customization****/
          if (data.customisation.length > 0) {
            this.getSubscriptionItem.forEach((item, i) => {
              data.customisation.forEach((customis) => {
                const cartcustindex = item.selectCustoizeID.findIndex(
                  (itmcust) =>
                    parseInt(itmcust) === parseInt(customis.customisation_id)
                );
                this.getSubscriptionItem[i].selectCustoizePrice[cartcustindex] =
                  customis.price;
              });
            });
          }
          this.$store.commit("product/customizeCartUpdate", true);
          this.$toasted.success(this.$t("Cart updated"), { duration: 3000 });
        } else {
          this.is_btn_spinner = false;
          alert(data.msg);
        }
      });
    },

    goToHome() {
      this.$router.go(-1);
    },





    /**
     * Cart clear for function.
     */
    // cleanCart() {
    //   this.$swal({
    //     title: this.$t("Are you sure you want to clear cart?"),
    //     type: "warning",
    //     customClass: {
    //       confirmButton: "cofirm-btn-new",
    //       cancelButton: "cancle-btn-new",
    //       title: "clear-cart-text mt-3 mb-1",
    //     },
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     confirmButtonText: this.$t("Yes"),
    //   }).then((result) => {
    //     // <--
    //     if (result.isConfirmed) {
    //       // <-- if confirmed
    //       // localStorage.setItem("driverTip", (this.driver_tip = 0));
    //       this.$store.commit("product/clearCartItems");
    //       localStorage.removeItem("place_order_data");
    //     }
    //   });
    // },

    readMore(value) {
      $("#moredesc" + value).toggle(400);
      $("#shortdec" + value).toggle(400);
    },


    getDelivery(value) {
      if (localStorage.getItem("pickupDeliveryFlag") == value) {
        return false;
      }
      if (value == 0 || value == "0") {
        this.deliveryCharge = 0;
        this.deliveryChargeTax = 0
      }
      this.removePromocode();
      this.$store.commit("order/pickupdelivery", value);
      localStorage.setItem("pickupDeliveryFlag", value);
      this.getDeliveryChrge();
    },

    floatNumberOnly(evt) {
      let val1 = evt.target.value;
      if (!(evt.keyCode == 46 || (evt.keyCode >= 48 && evt.keyCode <= 57))) {
        evt.preventDefault();
      }
      let partlen = val1.split(".").length - 1;
      if (evt.keyCode == 46 && partlen == 1) {
        evt.preventDefault();
      }
    },


    otherFeesModal() {
      $("#otherFeesModal").modal("show");
    },

    closeTexModal() {
      $("#otherFeesModal").modal("hide");
    },

  },
};
</script>

<style scoped>
.svprc {
  padding: 0 15px;
  background: rgba(var(--theme-primary));
  margin: 0 0px;
  color: #fff;
}

.svprc p {
  padding: 7px;
}

.delivery-address {
  padding: 15px;
  position: sticky;
}

.delivery-address .delivery-pickup-main {
  display: inline-flex !important;
}

.delivery-address .delivery-nw-section {
  display: contents;
}

.delivery-nw-section span {
  margin-right: 30px;
  font-weight: 500;
}

.delivery-nw-section .chng-addrs {
  margin-left: 24px;
}

.pckup-addr-cls .pickup-txt {
  margin-right: 32%;
  font-weight: 500;
}

button.btn.btn-primary.disabled,
.prcc-cls-blr,
.prcc-cls,
.prcc-cls:hover,
.prcc-cls-blr:hover {
  background: #27ae60;
  border: none;
}

.delivery-address p {
  white-space: unset;
  width: 100%;
  overflow: unset;
}

@media only screen and (max-width: 767px) {
  .delivery-address p {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }

  .pckup-addr-cls .pickup-txt {
    margin-right: 12%;
  }

  .delivery-pickup-main .delivery-pickup-lnk {
    width: 146px;
  }

  .delivery-nw-section span {
    font-size: 12px;
    margin-right: 0;
  }

  .delivery-nw-section .chng-addrs {
    margin-left: 1px;
    font-size: 12px;
  }
}

.date-wrapper {
  overflow: auto;
}

.date-list {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 3px;
}

.date-item {
  background-color: #e9e9e9;
  border-radius: 10px;
  min-width: 50px;
  text-align: center;
  font-size: 14px;
}

.date-day {
  background-color: rgba(var(--theme-primary));
  color: #fff;
  padding: 6px;
  border-radius: 10px;
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-num {
  padding: 6px;
}

.disabled-link {
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed;
}

.free-coupen {
  height: 25vh;
}
</style>
