var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{},[_c('div',{},[_c('div',{},[_c('div',{},[(!_vm.productDetail)?_c('skeleton-loader-vue',{attrs:{"type":"string","width":"50%","height":"20px"}}):_c('h5',{},[_vm._v(" "+_vm._s(_vm.productDetail.item_name)+" ")]),(_vm.tags && _vm.tags.length > 0)?_c('div',{staticClass:"tags-list tagslist"},[_c('ul',_vm._l((_vm.tags),function(tag,i){return _c('li',{key:i,style:({
                                background: tag.background_color + '!important',
                                color: tag.text_color,
                            })},[_vm._v(" "+_vm._s(tag.name)+" ")])}),0)]):_vm._e()],1),(_vm.customize_skelloader)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"product-content pt-3"},[_c('div',[_c('div',{staticClass:"cus-title"},[_c('skeleton-loader-vue',{attrs:{"type":"string","width":"37%","height":"20px"}}),_c('skeleton-loader-vue',{attrs:{"type":"string","width":"20%","height":"20px"}})],1),_c('div',[_c('div',{},[_c('label',{staticClass:"form__label-radio",staticStyle:{"padding-left":"0px"},attrs:{"for":"radio-0-0"}},[_c('skeleton-loader-vue',{staticClass:"form__radio",staticStyle:{"margin-right":"20px"},attrs:{"type":"circle","width":"20px","height":"20px"}}),_c('skeleton-loader-vue',{staticStyle:{"position":"absolute"},attrs:{"type":"string","width":"170px","height":"20px"}})],1),_c('span',{staticClass:"ch-price"},[_c('skeleton-loader-vue',{attrs:{"type":"string","width":"30px","height":"24px"}})],1)])])])])]):_vm._e(),(_vm.productDetail)?_c('div',{attrs:{"id":'item-id-' + _vm.productDetail.item_id}},[(_vm.productDetail.item_images.length > 0)?_c('VueSlickCarousel',_vm._b({staticClass:"silkprod",attrs:{"id":"prod-details-slider"}},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.productDetail.item_images),function(slide,index){return _c('div',{key:index},[_c('div',{staticClass:"testimonial-item equal-height style-6"},[_c('img',{staticClass:"item-img",attrs:{"src":slide.image_name},on:{"error":function($event){return _vm.onImgError()}}})])])}),0):_vm._e(),(
                        _vm.productDetail.customize_type.length > 0 &&
                        _vm.productDetail.item_description
                    )?_c('ul',{staticClass:"nav nav-tabs border-0 mb-3"},[_c('li',{staticClass:"nav-item"},[(_vm.productDetail.customize_type.length > 0)?_c('a',{staticClass:"nav-link navbar-default",class:_vm.productDetail.is_customizable.length > 0 ? 'active' : '',attrs:{"href":"#sort","data-bs-toggle":"tab"}},[_vm._v(_vm._s(_vm.$t("Customization")))]):_vm._e()]),(
                            _vm.productDetail.item_description ||
                            _vm.productDetail.custom_details.length > 0
                        )?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link navbar-default",attrs:{"href":"#cusines","data-bs-toggle":"tab"}},[_vm._v(_vm._s(_vm.$t("Info")))])]):_vm._e()]):_vm._e(),_c('form',{attrs:{"action":"javascript:void(0)"}},[_c('div',{staticClass:"tab-content"},[(_vm.productDetail.customize_type.length > 0)?_c('div',{staticClass:"tab-pane active tab-box",attrs:{"id":"sort"}},[_c('div',{staticClass:"container"},[(
                                        _vm.productDetail.customize_type.length > 0 &&
                                        !_vm.customize_skelloader
                                    )?_c('div',{staticClass:"product-content pt-3"},_vm._l((_vm.productDetail.customize_type),function(customize,customizeIndex){return _c('div',{key:customizeIndex},[_c('div',{staticClass:"cus-title"},[_c('h6',{staticClass:"cus-name"},[_vm._v(_vm._s(customize.name))]),(customize.is_selection != 0)?_c('p',[_vm._v(" Maximum "+_vm._s(customize.is_selection)+" items ")]):_vm._e()]),(
                                                (customize.is_min_selection == '1' &&
                                                    customize.is_selection == '1') ||
                                                customize.is_selection == '1'
                                            )?_c('div',{class:{
                                                'is-invalid':
                                                    _vm.$v.selectedCustomisation.customize_type[
                                                        'parent_' + customize.customize_type_id
                                                    ].value.$error,
                                            }},_vm._l((customize.option),function(optionData,index){return _c('div',{key:index,staticClass:"form-check form__radio-group"},[_c('div',{staticClass:"rdiotogl",attrs:{"data-bs-toggle":"collapse","href":'#collapseExample' + customizeIndex + index}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.selectedCustomisation.customize_type[
                                                                'parent_' + customize.customize_type_id
                                                            ].value.$model
                                                                ),expression:"$v.selectedCustomisation.customize_type[\n                                                                'parent_' + customize.customize_type_id\n                                                            ].value.$model\n                                                                "}],staticClass:"form__radio-input",attrs:{"type":"radio","name":'radio-' + customizeIndex,"id":'radio-' + customizeIndex + '-' + index},domProps:{"value":optionData.selection_id,"checked":_vm._q(_vm.$v.selectedCustomisation.customize_type[
                                                                'parent_' + customize.customize_type_id
                                                            ].value.$model
                                                                ,optionData.selection_id)},on:{"change":[function($event){return _vm.$set(_vm.$v.selectedCustomisation.customize_type[
                                                                'parent_' + customize.customize_type_id
                                                            ].value, "$model", optionData.selection_id)},function($event){return _vm.checkNested(
                                                                        $event,
                                                                        customize.customize_type_id
                                                                    )}]}}),_c('label',{staticClass:"form__label-radio",attrs:{"for":'radio-' + customizeIndex + '-' + index}},[_c('span',{staticClass:"form__radio-button"}),_vm._v(_vm._s(optionData.selection_name))]),_c('span',{staticClass:"ch-price"},[_c('span',{staticClass:"currency",domProps:{"innerHTML":_vm._s(_vm.$auth.setting.currency)}}),_vm._v(" "+_vm._s(optionData.selection_price)+" "),(optionData.options.length > 0)?_c('b-icon-arrow-right-circle-fill'):_vm._e()],1)]),(
                                                        (optionData.is_min_selection == '1' &&
                                                            optionData.is_selection == '1') ||
                                                        (optionData.is_selection == '1' &&
                                                            optionData.options.length > 0)
                                                    )?_c('div',{staticClass:"rdiotogllist"},[(
                                                            optionData.options.length > 0 &&
                                                            _vm.selectedCustomisation.customize_type[
                                                                'parent_' + customize.customize_type_id
                                                            ].value == optionData.selection_id
                                                        )?_c('div',{staticClass:"chld-rdio collapse show",class:{
                                                            'is-invalid':
                                                                _vm.$v.selectedCustomisation.customize_type[
                                                                    'parent_' + customize.customize_type_id
                                                                ]['child_' + optionData.selection_id].value
                                                                    .$error,
                                                        }},_vm._l((optionData.options),function(subOption,subi){return _c('div',{key:subi,staticClass:"form-check form__radio-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.selectedCustomisation.customize_type[
                                                                            'parent_' + customize.customize_type_id
                                                                        ]['child_' + optionData.selection_id]
                                                                            .value.$model
                                                                            ),expression:"$v.selectedCustomisation.customize_type[\n                                                                            'parent_' + customize.customize_type_id\n                                                                        ]['child_' + optionData.selection_id]\n                                                                            .value.$model\n                                                                            "}],staticClass:"form__radio-input",attrs:{"type":"radio","name":'radio-' + index + '-' + customizeIndex,"id":'radio-' +
                                                                        customizeIndex +
                                                                        '-' +
                                                                        index +
                                                                        '-' +
                                                                        subi},domProps:{"value":subOption.selection_id,"checked":_vm._q(_vm.$v.selectedCustomisation.customize_type[
                                                                            'parent_' + customize.customize_type_id
                                                                        ]['child_' + optionData.selection_id]
                                                                            .value.$model
                                                                            ,subOption.selection_id)},on:{"change":[function($event){return _vm.$set(_vm.$v.selectedCustomisation.customize_type[
                                                                            'parent_' + customize.customize_type_id
                                                                        ]['child_' + optionData.selection_id]
                                                                            .value, "$model", subOption.selection_id)},function($event){return _vm.checkNestedSub(
                                                                                    $event,
                                                                                    subOption.selection_id
                                                                                )}]}}),_vm._v(" "+_vm._s(subOption.is_selected)+" "),_c('label',{staticClass:"form__label-radio",attrs:{"for":'radio-' +
                                                                    customizeIndex +
                                                                    '-' +
                                                                    index +
                                                                    '-' +
                                                                    subi}},[_c('span',{staticClass:"form__radio-button"}),_vm._v(_vm._s(subOption.selection_name))]),_c('span',{staticClass:"ch-price"},[_c('span',{staticClass:"currency",domProps:{"innerHTML":_vm._s(_vm.$auth.setting.currency)}}),_vm._v(_vm._s(subOption.selection_price))])])}),0):_vm._e(),_c('div',{staticClass:"invalid-feedback"},[(
                                                                !_vm.$v.selectedCustomisation.customize_type[
                                                                    'parent_' + customize.customize_type_id
                                                                ]['child_' + optionData.selection_id].value
                                                                    .crequired
                                                            )?_c('span',[_vm._v(_vm._s(optionData.selection_name)+" "+_vm._s(_vm.$t("Maximum"))+" "+_vm._s(optionData.is_selection)+" "+_vm._s(_vm.$t("item select")))]):(
                                                                !_vm.$v.selectedCustomisation.customize_type[
                                                                    'parent_' + customize.customize_type_id
                                                                ]['child_' + optionData.selection_id].value
                                                                    .valueBetween
                                                            )?_c('span',[_vm._v(_vm._s(optionData.selection_name)+" "+_vm._s(_vm.$t("Maximum"))+" "+_vm._s(optionData.is_selection)+" "+_vm._s(_vm.$t("item select valid")))]):_vm._e()])]):_c('div',{staticClass:"custom-checkbox w-100"},[(
                                                            optionData.options.length > 0 &&
                                                            _vm.selectedCustomisation.customize_type[
                                                                'parent_' + customize.customize_type_id
                                                            ].value == optionData.selection_id
                                                        )?_c('div',{staticClass:"chld-rdio collapse show",class:{
                                                            'is-invalid':
                                                                _vm.$v.selectedCustomisation.customize_type[
                                                                    'parent_' + customize.customize_type_id
                                                                ]['child_' + optionData.selection_id].value
                                                                    .$error,
                                                        }},_vm._l((optionData.options),function(suboptionCheckbox,subindex){return _c('div',{key:subindex,staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.selectedCustomisation.customize_type[
                                                                                'parent_' + customize.customize_type_id
                                                                            ]['child_' + optionData.selection_id]
                                                                                .value.$model
                                                                                ),expression:"$v.selectedCustomisation.customize_type[\n                                                                                'parent_' + customize.customize_type_id\n                                                                            ]['child_' + optionData.selection_id]\n                                                                                .value.$model\n                                                                                "}],staticClass:"form-check-input",attrs:{"type":"checkbox","id":'subcheckbox-' +
                                                                        customizeIndex +
                                                                        '-' +
                                                                        index +
                                                                        '-' +
                                                                        subindex,"name":'subcheckbox_' +
                                                                            customizeIndex +
                                                                            index +
                                                                            '_' +
                                                                            subindex},domProps:{"value":suboptionCheckbox.selection_id,"checked":Array.isArray(_vm.$v.selectedCustomisation.customize_type[
                                                                                'parent_' + customize.customize_type_id
                                                                            ]['child_' + optionData.selection_id]
                                                                                .value.$model
                                                                                )?_vm._i(_vm.$v.selectedCustomisation.customize_type[
                                                                                'parent_' + customize.customize_type_id
                                                                            ]['child_' + optionData.selection_id]
                                                                                .value.$model
                                                                                ,suboptionCheckbox.selection_id)>-1:(_vm.$v.selectedCustomisation.customize_type[
                                                                                'parent_' + customize.customize_type_id
                                                                            ]['child_' + optionData.selection_id]
                                                                                .value.$model
                                                                                )},on:{"change":[function($event){var $$a=_vm.$v.selectedCustomisation.customize_type[
                                                                                'parent_' + customize.customize_type_id
                                                                            ]['child_' + optionData.selection_id]
                                                                                .value.$model
                                                                                ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=suboptionCheckbox.selection_id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.$v.selectedCustomisation.customize_type[
                                                                                'parent_' + customize.customize_type_id
                                                                            ]['child_' + optionData.selection_id]
                                                                                .value, "$model", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.$v.selectedCustomisation.customize_type[
                                                                                'parent_' + customize.customize_type_id
                                                                            ]['child_' + optionData.selection_id]
                                                                                .value, "$model", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.$v.selectedCustomisation.customize_type[
                                                                                'parent_' + customize.customize_type_id
                                                                            ]['child_' + optionData.selection_id]
                                                                                .value, "$model", $$c)}},function($event){return _vm.checkNestedSub($event)}]}}),_vm._v(_vm._s(suboptionCheckbox.is_selected)+" "),_c('label',{staticClass:"form-check-label",attrs:{"for":'subcheckbox-' +
                                                                    customizeIndex +
                                                                    '-' +
                                                                    index +
                                                                    '-' +
                                                                    subindex}},[_vm._v(" "+_vm._s(suboptionCheckbox.selection_name))]),_c('span',{staticClass:"ch-price"},[_c('span',{staticClass:"currency",domProps:{"innerHTML":_vm._s(_vm.$auth.setting.currency)}}),_vm._v(_vm._s(suboptionCheckbox.selection_price))])])}),0):_vm._e(),_c('div',{staticClass:"invalid-feedback"},[(
                                                                !_vm.$v.selectedCustomisation.customize_type[
                                                                    'parent_' + customize.customize_type_id
                                                                ]['child_' + optionData.selection_id].value
                                                                    .crequired
                                                            )?_c('span',[_vm._v(_vm._s(optionData.selection_name)+" Maximum "+_vm._s(optionData.is_selection)+" item select")]):(
                                                                !_vm.$v.selectedCustomisation.customize_type[
                                                                    'parent_' + customize.customize_type_id
                                                                ]['child_' + optionData.selection_id].value
                                                                    .valueBetween
                                                            )?_c('span',[_vm._v(_vm._s(optionData.selection_name)+" Maximum "+_vm._s(optionData.is_selection)+" item select valid")]):_vm._e()])])])}),0):_c('div',{staticClass:"custom-checkbox",class:{
                                                'is-invalid':
                                                    _vm.$v.selectedCustomisation.customize_type[
                                                        'parent_' + customize.customize_type_id
                                                    ].value.$error,
                                            }},_vm._l((customize.option),function(optionData,index){return _c('div',{key:index},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.selectedCustomisation.customize_type[
                                                                'parent_' + customize.customize_type_id
                                                            ].value.$model
                                                                ),expression:"$v.selectedCustomisation.customize_type[\n                                                                'parent_' + customize.customize_type_id\n                                                            ].value.$model\n                                                                "}],staticClass:"form-check-input",attrs:{"type":"checkbox","id":'checkbox-' + customizeIndex + '-' + index,"name":'checkbox_' + customizeIndex},domProps:{"value":optionData.selection_id,"checked":Array.isArray(_vm.$v.selectedCustomisation.customize_type[
                                                                'parent_' + customize.customize_type_id
                                                            ].value.$model
                                                                )?_vm._i(_vm.$v.selectedCustomisation.customize_type[
                                                                'parent_' + customize.customize_type_id
                                                            ].value.$model
                                                                ,optionData.selection_id)>-1:(_vm.$v.selectedCustomisation.customize_type[
                                                                'parent_' + customize.customize_type_id
                                                            ].value.$model
                                                                )},on:{"change":[function($event){var $$a=_vm.$v.selectedCustomisation.customize_type[
                                                                'parent_' + customize.customize_type_id
                                                            ].value.$model
                                                                ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=optionData.selection_id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.$v.selectedCustomisation.customize_type[
                                                                'parent_' + customize.customize_type_id
                                                            ].value, "$model", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.$v.selectedCustomisation.customize_type[
                                                                'parent_' + customize.customize_type_id
                                                            ].value, "$model", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.$v.selectedCustomisation.customize_type[
                                                                'parent_' + customize.customize_type_id
                                                            ].value, "$model", $$c)}},function($event){return _vm.checkNestedSub(
                                                                        $event,
                                                                        customize.customize_type_id
                                                                    )}]}}),_c('label',{staticClass:"form-check-label",attrs:{"for":'checkbox-' + customizeIndex + '-' + index}},[_vm._v(" "+_vm._s(optionData.selection_name)+" "+_vm._s(customize.customizeIndex))]),_c('span',{staticClass:"ch-price"},[_c('span',{staticClass:"currency",domProps:{"innerHTML":_vm._s(_vm.$auth.setting.currency)}}),_vm._v(_vm._s(optionData.selection_price)+" ")])]),(
                                                        (optionData.is_min_selection == '1' &&
                                                            optionData.is_selection == '1') ||
                                                        (optionData.is_selection == '1' &&
                                                            optionData.options.length > 0)
                                                    )?_c('div',[(
                                                            optionData.options.length > 0 &&
                                                            _vm.selectedCustomisation.customize_type[
                                                                'parent_' + customize.customize_type_id
                                                            ].value &&
                                                            _vm.selectedCustomisation.customize_type[
                                                                'parent_' + customize.customize_type_id
                                                            ].value.includes(optionData.selection_id)
                                                        )?_c('div',{staticClass:"chld-rdio",class:{
                                                            'is-invalid':
                                                                _vm.$v.selectedCustomisation.customize_type[
                                                                    'parent_' + customize.customize_type_id
                                                                ]['child_' + optionData.selection_id].value
                                                                    .$error,
                                                        }},_vm._l((optionData.options),function(subOption,subi){return _c('div',{key:subi,staticClass:"form-check form__radio-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.selectedCustomisation.customize_type[
                                                                            'parent_' + customize.customize_type_id
                                                                        ]['child_' + optionData.selection_id]
                                                                            .value.$model
                                                                            ),expression:"$v.selectedCustomisation.customize_type[\n                                                                            'parent_' + customize.customize_type_id\n                                                                        ]['child_' + optionData.selection_id]\n                                                                            .value.$model\n                                                                            "}],staticClass:"form__radio-input",attrs:{"type":"radio","name":'radio-' + index + '-' + customizeIndex,"id":'radio-' +
                                                                        customizeIndex +
                                                                        '-' +
                                                                        index +
                                                                        '-' +
                                                                        subi},domProps:{"value":subOption.selection_id,"checked":_vm._q(_vm.$v.selectedCustomisation.customize_type[
                                                                            'parent_' + customize.customize_type_id
                                                                        ]['child_' + optionData.selection_id]
                                                                            .value.$model
                                                                            ,subOption.selection_id)},on:{"change":[function($event){return _vm.$set(_vm.$v.selectedCustomisation.customize_type[
                                                                            'parent_' + customize.customize_type_id
                                                                        ]['child_' + optionData.selection_id]
                                                                            .value, "$model", subOption.selection_id)},function($event){return _vm.checkNestedSub($event)}]}}),_vm._v(" "+_vm._s(subOption.is_selected)+" "),_c('label',{staticClass:"form__label-radio",attrs:{"for":'radio-' +
                                                                    customizeIndex +
                                                                    '-' +
                                                                    index +
                                                                    '-' +
                                                                    subi}},[_c('span',{staticClass:"form__radio-button"}),_vm._v(_vm._s(subOption.selection_name))]),_c('span',{staticClass:"ch-price"},[_c('span',{staticClass:"currency",domProps:{"innerHTML":_vm._s(_vm.$auth.setting.currency)}}),_vm._v(_vm._s(subOption.selection_price))])])}),0):_vm._e(),_c('div',{staticClass:"invalid-feedback"},[(
                                                                !_vm.$v.selectedCustomisation.customize_type[
                                                                    'parent_' + customize.customize_type_id
                                                                ]['child_' + optionData.selection_id].value
                                                                    .crequired
                                                            )?_c('span',[_vm._v(_vm._s(optionData.selection_name)+" "+_vm._s(_vm.$t("Maximum 1 item select")))]):(
                                                                !_vm.$v.selectedCustomisation.customize_type[
                                                                    'parent_' + customize.customize_type_id
                                                                ]['child_' + optionData.selection_id].value
                                                                    .valueBetween
                                                            )?_c('span',[_vm._v(_vm._s(optionData.selection_name)+" "+_vm._s(_vm.$t("Maximum 1 item select valid")))]):_vm._e()])]):_c('div',{staticClass:"custom-checkbox w-100"},[(
                                                            optionData.options.length > 0 &&
                                                            _vm.selectedCustomisation.customize_type[
                                                                'parent_' + customize.customize_type_id
                                                            ].value &&
                                                            _vm.selectedCustomisation.customize_type[
                                                                'parent_' + customize.customize_type_id
                                                            ].value.includes(optionData.selection_id)
                                                        )?_c('div',{staticClass:"chld-rdio",class:{
                                                            'is-invalid':
                                                                _vm.$v.selectedCustomisation.customize_type[
                                                                    'parent_' + customize.customize_type_id
                                                                ]['child_' + optionData.selection_id].value
                                                                    .$error,
                                                        }},_vm._l((optionData.options),function(suboptionCheckbox,subindex){return _c('div',{key:subindex,staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.selectedCustomisation.customize_type[
                                                                            'parent_' + customize.customize_type_id
                                                                        ]['child_' + optionData.selection_id]
                                                                            .value.$model
                                                                            ),expression:"$v.selectedCustomisation.customize_type[\n                                                                            'parent_' + customize.customize_type_id\n                                                                        ]['child_' + optionData.selection_id]\n                                                                            .value.$model\n                                                                            "}],staticClass:"form-check-input",attrs:{"data-id":customize.customize_type_id +
                                                                    ',' +
                                                                    optionData.selection_id,"type":"checkbox","id":'subcheckbox-' +
                                                                        customizeIndex +
                                                                        '-' +
                                                                        index +
                                                                        '-' +
                                                                        subindex},domProps:{"value":suboptionCheckbox.selection_id,"checked":Array.isArray(_vm.$v.selectedCustomisation.customize_type[
                                                                            'parent_' + customize.customize_type_id
                                                                        ]['child_' + optionData.selection_id]
                                                                            .value.$model
                                                                            )?_vm._i(_vm.$v.selectedCustomisation.customize_type[
                                                                            'parent_' + customize.customize_type_id
                                                                        ]['child_' + optionData.selection_id]
                                                                            .value.$model
                                                                            ,suboptionCheckbox.selection_id)>-1:(_vm.$v.selectedCustomisation.customize_type[
                                                                            'parent_' + customize.customize_type_id
                                                                        ]['child_' + optionData.selection_id]
                                                                            .value.$model
                                                                            )},on:{"change":[function($event){var $$a=_vm.$v.selectedCustomisation.customize_type[
                                                                            'parent_' + customize.customize_type_id
                                                                        ]['child_' + optionData.selection_id]
                                                                            .value.$model
                                                                            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=suboptionCheckbox.selection_id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.$v.selectedCustomisation.customize_type[
                                                                            'parent_' + customize.customize_type_id
                                                                        ]['child_' + optionData.selection_id]
                                                                            .value, "$model", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.$v.selectedCustomisation.customize_type[
                                                                            'parent_' + customize.customize_type_id
                                                                        ]['child_' + optionData.selection_id]
                                                                            .value, "$model", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.$v.selectedCustomisation.customize_type[
                                                                            'parent_' + customize.customize_type_id
                                                                        ]['child_' + optionData.selection_id]
                                                                            .value, "$model", $$c)}},function($event){return _vm.checkNestedSub(
                                                                                    $event,
                                                                                    suboptionCheckbox.customize_type_id,
                                                                                    suboptionCheckbox.parent_item_id
                                                                                )}]}}),_vm._v(_vm._s(suboptionCheckbox.is_selected)+" "),_c('label',{staticClass:"form-check-label",attrs:{"for":'subcheckbox-' +
                                                                    customizeIndex +
                                                                    '-' +
                                                                    index +
                                                                    '-' +
                                                                    subindex}},[_vm._v(" "+_vm._s(suboptionCheckbox.selection_name))]),_c('span',{staticClass:"ch-price"},[_c('span',{staticClass:"currency",domProps:{"innerHTML":_vm._s(_vm.$auth.setting.currency)}}),_vm._v(_vm._s(suboptionCheckbox.selection_price))])])}),0):_vm._e(),_c('div',{staticClass:"invalid-feedback"},[(
                                                                !_vm.$v.selectedCustomisation.customize_type[
                                                                    'parent_' + customize.customize_type_id
                                                                ]['child_' + optionData.selection_id].value
                                                                    .crequired
                                                            )?_c('span',[_vm._v(_vm._s(optionData.selection_name)+" "+_vm._s(_vm.$t("Maximum"))+" "+_vm._s(optionData.is_selection)+" "+_vm._s(_vm.$t("item select")))]):(
                                                                !_vm.$v.selectedCustomisation.customize_type[
                                                                    'parent_' + customize.customize_type_id
                                                                ]['child_' + optionData.selection_id].value
                                                                    .valueBetween
                                                            )?_c('span',[_vm._v(_vm._s(optionData.selection_name)+" "+_vm._s(_vm.$t("Maximum"))+" "+_vm._s(optionData.is_selection)+" "+_vm._s(_vm.$t("item select valid")))]):_vm._e()])])])}),0),(
                                                _vm.$v.selectedCustomisation.customize_type[
                                                    'parent_' + customize.customize_type_id
                                                ].value.$error
                                            )?_c('div',{staticClass:"invalid-feedback"},[(
                                                    !_vm.$v.selectedCustomisation.customize_type[
                                                        'parent_' + customize.customize_type_id
                                                    ].value.crequired
                                                )?_c('span',[(customize.is_min_selection == 1)?_c('span',[_vm._v(_vm._s(customize.name)+" "+_vm._s(_vm.$t('Required Minimun 1 item select')))]):_vm._e(),(customize.is_min_selection != 1)?_c('span',[_vm._v(_vm._s(customize.name)+" "+_vm._s(_vm.$t("Required Minimum"))+" "+_vm._s(customize.is_min_selection)+" "+_vm._s(_vm.$t("items")))]):_vm._e()]):(
                                                    !_vm.$v.selectedCustomisation.customize_type[
                                                        'parent_' + customize.customize_type_id
                                                    ].value.valueBetween
                                                )?_c('span',[(customize.is_min_selection == 1)?_c('span',[_vm._v(_vm._s(customize.name)+" "+_vm._s(_vm.$t('Required Minimun 1 item select')))]):_vm._e(),(customize.is_min_selection != 1)?_c('span',[_vm._v(_vm._s(customize.name)+" "+_vm._s(_vm.$t("Required Minimum"))+" "+_vm._s(customize.is_selection)+" "+_vm._s(_vm.$t("items")))]):_vm._e()]):_vm._e()]):_vm._e()])}),0):_vm._e()])]):_vm._e(),_c('div',{staticClass:"tab-pane tab-box",class:_vm.productDetail.customize_type.length == '0' &&
                                (_vm.productDetail.item_description ||
                                    _vm.productDetail.custom_details.length != 0)
                                ? 'active'
                                : '',attrs:{"id":"cusines"}},[_c('div',{staticClass:"accordion mt-4",attrs:{"id":"accordionExample"}},[(_vm.productDetail.item_description)?_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"collapse show",attrs:{"id":"collapseOne","aria-labelledby":"headingOne","data-parent":"#accordionExample"}},[_c('div',{staticClass:"card-body"},[_vm._v(" "+_vm._s(_vm.productDetail.item_description)+" ")])])]):_vm._e(),(_vm.productDetail.custom_details.length > 0)?_c('div',_vm._l((_vm.productDetail.custom_details),function(detail,index){return _c('div',{key:index + 1,staticClass:"card"},[_c('div',{staticClass:"card-header",attrs:{"id":`headingOne-${index}`}},[_c('h2',{staticClass:"mb-0"},[_c('button',{staticClass:"btn btn-link btn-block text-start collapsed w-100",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":`#collapseOne-${index}`,"aria-expanded":"true","aria-controls":`collapseOne-${index}`}},[_vm._v(" "+_vm._s(detail.custom_key)+" ")])])]),_c('div',{staticClass:"collapse",attrs:{"id":`collapseOne-${index}`,"aria-labelledby":`headingOne-${index}`,"data-parent":"#accordionExample"}},[_c('div',{staticClass:"card-body"},[_vm._v(_vm._s(detail.custom_value))])])])}),0):_vm._e()])])])])],1):_vm._e(),(!_vm.closeLabel && _vm.pagename != 'qr')?_c('div',{staticClass:"modal-footer"},[(_vm.productDetail &&
                        _vm.productDetail.is_sold_out == '0' &&
                        _vm.productDetail.subscription_status == '1' && _vm.isPickupDelivery == 1)?_c('button',{staticClass:"add-to-cart btn btn-outline-warning btn-sm w-100",on:{"click":function($event){return _vm.addSubscription(_vm.productDetail)}}},[_vm._v(" "+_vm._s(_vm.$t("Subscribe 14514"))+" ")]):_vm._e(),(_vm.productDetail && _vm.productDetail.is_sold_out == '0')?_c('div',{staticClass:"quantityInc"},[_c('div',{staticClass:"number d-flex align-items-center"},[_c('span',{staticClass:"minus d-flex justify-content-center align-items-center",on:{"click":function($event){return _vm.decrementItem()}}},[_vm._v("-")]),_c('input',{attrs:{"type":"text","readonly":""},domProps:{"value":_vm.quantityTotal}}),_c('span',{staticClass:"plus d-flex justify-content-center align-items-center",on:{"click":function($event){return _vm.incrementItem()}}},[_vm._v("+")])])]):_vm._e(),(_vm.productDetail && _vm.productDetail.is_sold_out == '0')?_c('button',{staticClass:"add-to-cart btn btn-outline-warning btn-sm",on:{"click":_vm.addProductToCart}},[_vm._v(" "+_vm._s(_vm.$t("Add To Cart "))+"("),_c('span',{staticClass:"currency",domProps:{"innerHTML":_vm._s(_vm.$auth.setting.currency)}}),_vm._v(" "+_vm._s((_vm.getTotalOptionPrice() * this.quantityTotal).toFixed(2))+") ")]):_vm._e(),(
                        _vm.productDetail &&
                        _vm.productDetail.is_sold_out != '0' &&
                        !_vm.customize_skelloader
                    )?_c('button',{staticClass:"sold add-to-cart btn btn-outline-warning btn-sm"},[_vm._v(" "+_vm._s(_vm.$t("Sold Out"))+" ")]):_vm._e()]):_vm._e()])])]),(_vm.isAlert)?_c('AlertModal',{attrs:{"DisplayAlertData":_vm.AlertData},on:{"modalClose":_vm.onModalCloseFun}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header",attrs:{"id":"headingOne"}},[_c('h2',{staticClass:"mb-0"},[_c('button',{staticClass:"btn btn-link btn-block text-start collapsed w-100",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#collapseOne","aria-expanded":"true","aria-controls":"collapseOne"}},[_vm._v(" Description ")])])])
}]

export { render, staticRenderFns }