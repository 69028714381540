import OrderHistory from '../apis/orderhistory'
import OrderDetail from '../apis/orderdetails'
import Order from '../apis/order'

const state = {
  onGoingOrders: [],
  orderDetail: [],
  place_order_data: [],
  isPickupDelivery: '1',
  pastOrdersData: [],
}
const actions = {
  getOnGoingOrder({ commit }, data) {
    return OrderHistory.OnGoingOrders(data).then((response) => {
      if (response.data.code == 200) {
        commit('getOnGoingOrder', response.data.Result)
      }
      return response.data
    })
  },

  getPastOrder({ commit }, data) {
    return OrderHistory.PastOrders(data).then((response) => {
      if (response.data.code == 200) {
        // commit('pastOrderData', response.data.Result)
        return response.data
      }
      return response.data
    })
  },

  viewOrderDetail({ commit }, data) {
    return OrderDetail.OrderDetails(data).then((response) => {
      if (response.data.code == 200) {
        commit('viewOrderDetail', response.data.Result)
      }
      return response.data
    })
  },

  placeOrder({ commit }, data) {
    return Order.placeOrder(data).then((response) => {
      if (response.data.code == 200) {
        // commit('placeOrder', response.data.Result)
        return response.data
      }
      return response.data
    })
  },
  placeOrderData({ commit }, data) {
    commit('placeOrderData', data)
  },

  orderRatingData({ commit }, data) {
    return Order.addOrderRating(data).then((response) => {
      if (response.data.code == 200) {
        //commit('orderRating', response.data.Result)
        return response.data
      }
      return response.data
    })
  },

  checkOrderRating({ commit }, data) {
    return Order.checkRating(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  reorderDataDetails({ commit }, data) {
    return Order.reorderDetails(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  viewTrackOrderDetail({ commit }, data) {
    return Order.trackOrderCustomer(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  postUploadOrderImages({ commit }, data) {
    return Order.uploadOrderImages(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  postremoveOrderImages({ commit }, data) {
    return Order.removeOrderImages(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  getOrderDistancekm({ commit }, data) {
    return Order.getOrderDistance(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  getPorterDeliveryCharges({ commit }, data) {
    return Order.getPorterCharges(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  deliveryZone({ commit }, data) {
    return Order.deliveryZone(data).then((response) => {
      if (response.data.code == 200) {
        localStorage.setItem('is_delivery_zone_available', response.data.settings.is_delivery_zone_available)
        return response.data
      }
      return response.data
    })
  },

  getOnGoingSubscription({ commit }, data) {
    return OrderHistory.getOnGoingSubscription(data).then(response => {
      if (response.data.code == 200) {
        commit('getOnGoingSubscription', response.data.Result)
      }
      return response.data;
    })
  },

  viewSubscriptionDetail({ commit }, data) {
    return OrderDetail.SubscriptionDetail(data).then(response => {
      if (response.data.code == 200) {
        commit('viewSubscriptionDetail', response.data.subscription)
      }
      return response.data;
    })
  },

  SubscriptionRestartPaused({ commit }, data) {
    return OrderDetail.SubscriptionRestartPaused(data).then(response => {
      if (response.data.code == 200) {

        return response.data;
      }
      return response.data;
    })
  },
  CancelSubscription({ commit }, data) {
    return OrderDetail.CancelSubscription(data).then(response => {
      if (response.data.code == 200) {
        return response.data;
      }
      return response.data;
    })
  },
  EditSubscription({ commit }, data) {
    return OrderDetail.UpdateSubscription(data).then(response => {
      if (response.data.code == 200) {
        return response.data;
      }
      return response.data;
    })
  },
  createInvoice({ commit }, data) {
    return OrderDetail.GenrateInvoice(data).then(response => {
      if (response.data.code == 200) {
        return response.data;
      }
      return response.data;
    })
  },

  getCurrentOrdersWithratingsList({ commit }, data) {
    return Order.getCurrentOrdersWithratings(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  getSubscriptionOrder({ commit }, data) {
    return Order.getSubscription(data).then(response => {
      if (response.data.code == 200) {
        return response.data;
      }
      return response.data;
    })
  },
}
const mutations = {
  getOnGoingOrder(state, onGoingOrders) {
    state.onGoingOrders = [...onGoingOrders]
  },
  getOnGoingSubscription(state, data) {
    state.onGoingSubscriptions = data
  },
  viewOrderDetail(state, orderDetail) {
    state.orderDetail = [...orderDetail]
  },
  viewSubscriptionDetail(state, data) {
    state.subscriptionDetail = data
  },
  placeOrderData(state, orderData) {
    state.place_order_data = { ...orderData }
  },

  clearPlaceOrderData(state) {
    state.place_order_data = []
    localStorage.setItem('place_order_data', [])
  },
  pickupdelivery(state, is_delivery) {
    state.isPickupDelivery = is_delivery
  },
  pastOrderData(state, data) {
    state.pastOrdersData = data
  },
}
const getters = {
  onGoingOrders: (state) => state.onGoingOrders,
  pastOrdersData: (state) => state.pastOrdersData,
  orderDetail: (state) => state.orderDetail,
  place_order_data: (state) => state.place_order_data,
  isPickupDelivery: (state) => state.isPickupDelivery,
}

export const order = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
